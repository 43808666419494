import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import TransactionAnalytics, {
  TotalTransactionVolume,
} from "./transactionAnalytics";
import TransactionHistory from "./transactionHistory";
import { PeriodConstants, getTotalExpense } from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCar,
  faWrench,
  faPhone,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons";
import { Car, EmptyWalletAdd, CallCalling } from "iconsax-react";
import Wrench from "./wrenchsvg";
import { Link } from "react-router-dom";

import { FiMenu, FiX } from "react-icons/fi";
import axios from "axios";
import styled from "styled-components";
import { BulkNotificationModal } from "./BulkNotificationModal";
// import mixpanel from "mixpanel-browser"

// mixpanel.init("fd917c03a5beff421e281e3937e2f7a1", {debug: true, track_pageview: "full-url", persistence: 'localStorage'});

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

const Dashboard = ({ userEmail }) => {
  // Initialize chart instance ref
  const [loading, setLoading] = useState(true);
  const [showSidebar, setShowSidebar] = useState(false);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [processedData, setProcessedData] = useState(transactionHistoryData);
  const [registeredMechanics, setRegisteredMechanics] = useState(0);
  const [totalCalls, setTotalCalls] = useState(0);
  const [registeredCarOwners, setRegisteredCarOwners] = useState([]);
  const [monthExpense, setMonthExpense] = useState(0);
  const [expenseDuration, setExpenseDuration] = useState(
    PeriodConstants.aMonth
  );
  const [searchValue, setSearchValue] = useState();
  const [filterValue, setFilterValue] = useState();
  const [showBulkModal, setShowBulkModal] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const accessToken = localStorage.getItem("authAccessToken");
        if (!accessToken) {
          console.error("Authentication credentials were not provided.");
          setLoading(false); // Set loading to false on authentication error
          return;
        }

        const response = await axios.get(`${baseUrl}/user/users/car_owners/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        console.log("Registered Car Owners Response:", response.data);

        // Extract car owners data from the response (assuming data structure)
        const carOwners = response.data.data || [];

        // Update the state with the car owners data
        setRegisteredCarOwners(carOwners);
      } catch (error) {
        console.error("Error fetching registered car owners data:", error);

        // Differentiate between server errors and network errors
        if (error.response) {
          // Server responded with a status code that falls outside the range of 2xx
          console.error(
            `Server error (Status Code: ${error.response.status})`,
            error.response.data
          );
        } else if (error.request) {
          // The request was made but no response was received
          console.error("Network error:", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error("Error:", error.message);
        }
      } finally {
        setLoading(false); // Set loading to false regardless of success or error
      }
    };

    fetchData();
  }, []);

  // Getting the Registered Mechanics
  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken"); // Get the access token from localStorage

    // Check if the access token is available and not expired
    if (accessToken) {
      axios
        .get(
          `${baseUrl}
/user/users/mechanics/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Set the access token in the headers
            },
          }
        )
        .then((response) => {
          console.log("Registered Mechanics Response:", response.data);

          // Extract the number of registered mechanics from the response
          const numberOfMechanics = parseInt(response.data?.data?.length) || 0;

          // Update the state with the number of registered mechanics
          setRegisteredMechanics(numberOfMechanics);
        })
        .catch((error) => {
          console.error("Error fetching registered mechanics data:", error);
        });
    } else {
      // Handle the case when the access token is not available or expired
      console.error("Authentication credentials were not provided.");
    }
  }, []);

  // Get the total number of Calls
  useEffect(() => {
    // Fetch the total number of calls from the API with authentication
    const accessToken = localStorage.getItem("authAccessToken"); // Get the access token from localStorage

    // Check if the access token is available and not expired
    if (accessToken) {
      axios
        .get(
          `${baseUrl}
/stats/call-statistics/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`, // Set the access token in the headers
            },
          }
        )
        .then((response) => {
          console.log("Total Calls Response:", response.data);

          // Extract the true total number of calls from the response
          const trueTotalCalls = response.data.total_calls || 0;

          // Update the totalCalls state with the obtained value
          setTotalCalls(trueTotalCalls);
        })
        .catch((error) => {
          console.error("Error fetching total calls data:", error);
        });
    } else {
      // Handle the case when the access token is not available or expired
      console.error("Authentication credentials were not provided.");
    }
  }, []);

  // Fetch transaction history data from the API with authentication
  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(
          `${baseUrl}
/stats/transaction-history/`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          console.log("Transaction History Response:", response.data);

          const dataFromEndpoint = response.data || {};
          setTransactionHistoryData(dataFromEndpoint);
        })
        .catch((error) => {
          console.error("Error fetching transaction history data:", error);
        });
    } else {
      console.error("Authentication credentials were not provided.");
    }
  }, []);

  useEffect(() => {
    if (transactionHistoryData?.history?.length > 0) {
      setProcessedData(transactionHistoryData.history);
      setMonthExpense(
        getTotalExpense(transactionHistoryData.history, expenseDuration)
      );
    }
  }, [transactionHistoryData, expenseDuration]);
  // useEffect(() => {
  //   const ctx = chartRef.current?.getContext('2d');
  //   if (chartInstanceRef.current) {
  //     chartInstanceRef.current.destroy();
  //   }

  //   chartInstanceRef.current = new Chart(ctx, {
  //     type: 'line',
  //     data: {
  //       labels: chartLabels,
  //       datasets: [
  //         {
  //           label: 'Transaction amount',
  //           data: chartData,
  //           borderColor: '#000000',
  //           borderWidth: 4,
  //           pointRadius: 0,
  //           fill: true,
  //           tension: 0.5,
  //           backgroundColor: 'rgba(0, 0, 0, 0.1)',
  //         },
  //       ],
  //     },
  //     options: {
  //       responsive: true,
  //       scales: {
  //         x: {
  //           type: 'category',
  //           grid: {
  //             display: false,
  //           },
  //         },
  //         y: {
  //           type: 'linear',
  //           grid: {
  //             color: '#e0e0e0',
  //           },
  //           ticks: {
  //             display: false,
  //             stepSize: 20,
  //           },
  //         },
  //       },
  //       plugins: {
  //         legend: {
  //           display: false,
  //         },
  //         tooltip: {
  //           mode: 'index',
  //           intersect: false,
  //         },
  //       },
  //     },
  //   });
  // }, [chartData, chartLabels]);

  useEffect(() => {
    if(searchValue =="clear"){
      const newData = transactionHistoryData?.history?.filter(function(aTransaction){
        return aTransaction;
      });
      setProcessedData((init) => newData);
    }else{
      const newData = transactionHistoryData?.history?.filter(function(aTransaction){
        return String(`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name} ${aTransaction.amount} ${String(new Date(String(aTransaction.date_created))).slice(3,16)} `)
          .toLowerCase()
          .includes(searchValue?.toLowerCase());
      });
      setProcessedData((init) => newData);
    }
  }, [searchValue]);

  useEffect(() => {
    switch (true) {
      case filterValue === "owner":
        {
          const newData = transactionHistoryData?.history?.filter(function (
            aTransaction
          ) {
            return aTransaction.user_detail.account_type === "owner";
          });
          setProcessedData((init) => newData);
        }
        break;
      case filterValue === "mechanic":
        {
          const newData = transactionHistoryData?.history?.filter(function (
            aTransaction
          ) {
            return aTransaction.user_detail.account_type === "mechanic";
          });
          setProcessedData((init) => newData);
        }
        break;
      case filterValue === "credit":
        {
          const newData = transactionHistoryData?.history?.filter(function (
            aTransaction
          ) {
            return aTransaction.transaction_type === "fund";
          });
          setProcessedData((init) => newData);
        }
        break;
      case filterValue === "debit":
        {
          const newData = transactionHistoryData?.history?.filter(function (
            aTransaction
          ) {
            return aTransaction.transaction_type !== "fund";
          });
          setProcessedData((init) => newData);
        }
        break;
    }
    // const newData = transactionHistoryData?.history?.filter(function(aTransaction){
    //   return String(`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name} ${aTransaction.amount} `).toLowerCase().includes(searchValue.toLowerCase());
    // })
    // setProcessedData(init=>newData)
  }, [filterValue]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  function handleChangeExpenseDuration(value) {
    setExpenseDuration((init) => value);
  }

  function handleSearchChange(value) {
    setSearchValue((init) => value);
  }

  function handelFilterChange(value) {
    setFilterValue((init) => value);
  }
 
  return (
    <>
      <div className="overall-dashboard-div flex h-screen bg-white-100">
        {/* Hamburger Icon */}
        <div className="hamburger-icon" onClick={toggleSidebar}>
          <FiMenu className="hamburger" />
        </div>

        <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

        <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
          <div className={`dashboard-content ${showSidebar ? "sidebar" : ""}`}>
            {showSidebar && (
              <div className="close-sidebar-button" onClick={toggleSidebar}>
                <FiX className="close-icon" />
              </div>
            )}
            <TopHeader userEmail={userEmail} />

            <Flex>
              <div className="p-8">
                <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
                  Dashboard
                </h2>
                <p className="dashboard-text mt-2 text-gray-400">
                  Track and manage the total number of application downloads,
                  registered car owners, and mechanics
                </p>
              </div>

              <button className="notif-button" onClick={() => setShowBulkModal(true)}>Bulk Notification</button>
            </Flex>

            <div className="dashboard-stats items-stretch grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 ml-8 mr-8">
              <Link to={"/car-owners"} className="dashboard-stat-container">
                <div className=" h-full rounded-lg flex flex-col justify-around border-gray-100 border p-4 shadow-md">
                  <div className="dashboard-stat-icon bg-green-200 rounded-full p-2 w-fit flex items-center justify-center">
                    <Car className="text-green-600 text-2xl" />
                  </div>
                  <h3 className="mt-2 text-md text-slate-500 ">
                    Registered Car Owners
                  </h3>
                  <p className="mt-2 text-2xl font-bold">
                    {registeredCarOwners.length}
                  </p>
                </div>
              </Link>
              <Link to={"/mechanics"} className="dashboard-stat-container">
                <div className="h-full rounded-lg flex flex-col justify-around border-gray-100 border p-4 shadow-md">
                  <div className="dashboard-stat-icon bg-orange-200 rounded-full p-2 w-fit flex items-center justify-center">
                    {/* <img src={Wrench} className='text-2xl text-orange-600'/> */}
                    <Wrench className="text-2xl text-orange-600" />
                  </div>
                  <h3 className="mt-2 text-text-md text-slate-500 ">
                    Registered Mechanics
                  </h3>
                  <p className="mt-2 text-2xl font-bold">{registeredMechanics}</p>
                </div>
              </Link>
              <Link to={"/call-logs"} className="dashboard-stat-container">
                <div className=" h-full rounded-lg flex flex-col justify-around border-gray-100 border p-4 shadow-md">
                  <div className="dashboard-stat-icon bg-blue-200 rounded-full p-2 w-fit flex items-center justify-center">
                    <CallCalling className="text-blue-500 text-2xl" />
                  </div>
                  <h3 className="mt-2 text-md text-slate-500 ">
                    Total Number of Calls
                  </h3>
                  <p className="mt-2 text-2xl font-bold">{totalCalls}</p>
                </div>
              </Link>
              <Link to={"/transactions"} className="dashboard-stat-container">
                <div className=" h-full rounded-lg border-gray-100 flex flex-col justify-around border p-4 shadow-md">
                  <div className="dashboard-stat-icon bg-pink-200 rounded-full p-2 w-fit flex items-center justify-center">
                    <EmptyWalletAdd className="text-pink-500 text-2xl" />
                  </div>
                  <h3 className="mt-2 text-base text-slate-500 ">
                    Total Number of Transactions
                  </h3>
                  <p className="mt-2 text-2xl font-bold">
                    {transactionHistoryData.total_transactions}
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-full flex p-4 items-start justify-between">
              <TransactionAnalytics rawData={transactionHistoryData?.history} />
              <TotalTransactionVolume
                amount={monthExpense}
                handleChange={handleChangeExpenseDuration}
              />
            </div>

            <TransactionHistory
              data={processedData}
              handleSearch={handleSearchChange}
              handleChange={handelFilterChange}
            />

            
          </div>
        </div>
      </div>

      <BulkNotificationModal 
        showModal={showBulkModal}
        setShowModal={setShowBulkModal}
      />
    </>
  );
};

export default Dashboard;

const Flex = styled.div`
  display: flex;
  align-items: center;
  margin-right: 2rem;
  justify-content: space-between;

  .notif-button {
    color: #ffffff;
    cursor: pointer;
    padding: 12px 20px;
    border-radius: 10px;
    background-color: rgb(34 197 94);
  }
`;
