
import { useNavigate,Link } from "react-router-dom";

import {Header,Footer} from "../components";


export default function Thanks(){


    return <div className = " relative ">
                <Header/>
                <Body/>
                <Footer/>
    </div>
}


function Body(){
    const navigate = useNavigate();

    function handleButtonClick(){
        navigate("/detailing/book");
    }
    return <div className="min-h-[100vh] w-full flex flex-col justify-center gap-12 items-center">
                <div className="flex flex-col gap-4 items-center justify-center">
                    <h1 className="text-4xl lg:text-7xl">We'll be in touch soon </h1>
                    <Link to={"/detailing"} className="text-green-600 text-xl">{`Return back to homepage >`}</Link>
                </div>
                <div className="flex flex-col gap-4 items-center justify-center">
                    <p className="text-2xl lg:text-5xl">Already know what you want?</p>
                    <button className="bg-black w-[70%] lg:w-[40%] py-3 rounded-xl text-white" onClick={handleButtonClick}>Book Online Here</button>
                </div>
            </div>
}