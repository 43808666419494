import axios from "axios";

const hostname = window.location.hostname;
const isProd = hostname.includes("requestmechanic");

function getBaseUrl() {
    
    return isProd 
      ? "https://requestnowmechanic.com/v1" 
      : "https://requestnowmechanic.com/staging/v1";
}
  
   const baseUrl = getBaseUrl();
  console.log(baseUrl);
  console.log(hostname);

//endpoint for the user login 
export default class AuthService {
    static async loginUser (data) {
        const res = await axios.post(`${baseUrl}
/user/login/`, data);
        // console.log(res.data, "resss.data");
        return res?.data || res;
    }

    static async rememberMeLogin (data, accessToken) {
        const res = await axios.post(
            `${baseUrl}
/user/login/`, 
            data,
            {
                headers:{
                    Authorization:`Bearer ${accessToken}`
                }
            }
        );
        // console.log(res.data, "resss.data");
        return res?.data || res;
    }
    static async verifyOtp (data) {
        const res = await axios.post(`${baseUrl}
/user/verify-otp/`, data);
        return res?.data || res;
    }
    static async resendOtp (data) {
        const res = await axios.post(`${baseUrl}
/user/send-otp/`, data);
        return res?.data || res;
    }
}
