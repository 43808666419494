import axios from "axios";

const hostname = window.location.hostname;
const isProd = hostname.includes("requestmechanic");

function getBaseUrl() {
    
    return isProd 
      ? "https://requestnowmechanic.com/v1" 
      : "https://requestnowmechanic.com/staging/v1";
}
  
   const baseUrl = getBaseUrl();
  console.log(baseUrl);
  console.log(hostname);
export default class UserService {
  
  static async userCreateAccountStep1(data) {
    const res = await axios.post(`${baseUrl}/user/registration/step1/`, data);
    return res?.data || res;
  }

  static async getUserRegistrationStep2() {
    const res = await axios.get(`${baseUrl}/user/registration/step2/`);
    return res?.data || res;
  }

  static async userCreateAccountStep2(data, accessToken) {
    const res = await axios.put(
      `${baseUrl}/user/registration/step2/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res?.data || res;
  }

  static async getCarBrands() {
    const res = await axios.get(`${baseUrl}/user/car-brands/`);
    return res?.data || res;
  }

  static async getMechanicServices() {
    const res = await axios.get(`${baseUrl}/client/services/`);
    return res?.data || res;
  }

  static async mechanicCreateAccountStep3(data, accessToken) {
    const form = new FormData();
    Object.keys(data).forEach(key => form.append(key, data[key]));

    const res = await axios.put(
      `${baseUrl}/user/registration/step3/mechanic/`,
      form,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res?.data || res;
  }

  static async updateProfilePicture(data, accessToken) {
    const res = await axios.put(
      `${baseUrl}/user/profile/update-profile-picture/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res?.data || res;
  }

  static async postSupportingDocument(data, accessToken) {
    const res = await axios.put(
      `${baseUrl}/user/supporting-documents/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return res?.data || res;
  }

  static async carOwnerCreateAccountStep3(data, accessToken) {
    const res = await axios.put(
      `${baseUrl}/user/registration/step3/owner/`,
      data,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res?.data || res;
  }

  static async resetPassword(data) {
    const res = await axios.post(`${baseUrl}/user/password-reset/`, data);
    return res?.data || res;
  }

  static async setNewPassword(data) {
    const res = await axios.put(`${baseUrl}/user/set-new-password/`, data);
    return res?.data || res;
  }
}
