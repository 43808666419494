
import { Link } from "react-router-dom";
import { Footer,Header} from "../components";


export default function Services(){
    return <div className="w-screen">
                <Header/>
                <AlistOfServices />
                <Footer/>
            </div>
}


 function AlistOfServices(){
    const services = [
        {img:"",price:"5,000",buttonLink:"/detailing/book",title:"Basic Wash",perks:"Exterior Wash, tire cleaning and basic interior vacuum"},
        {img:"",price:"15,000",buttonLink:"/detailing/book",title:"Premium Detail",perks:"Full Exterior detail, interior deep clean and waxing"},
        {img:"",price:"25,000",buttonLink:"/detailing/book",title:"Executive Package",perks:"Complete interior and exterior detailing with ceramic coating"}
    ]
    return <div className="w-full pt-[100px] flex lg:gap-4 mb-8 min-h-[80vh] justify-center items-center flex-wrap">
                {
                    services.map(function(aService){
                        return <Aservice img={aService.img} price={aService.price} buttonLink={aService.buttonLink} title={aService.title} perks={aService.perks} />
                    })

                }
    </div>
 }

 function Aservice({img,price,title,perks,buttonLink}){
    return <div className="w-[85%] lg:w-[25%] border-[0.01rem] h-fit shadow-lg border-solid border-slate-300 rounded-md">
                <div className="w-full bg-green-400">
                    <img src={img} className="w-full object-cover" />
                </div>
                <div className=" p-4 flex flex-col gap-3">
                    <p className="text-xl font-dmsans font-black">{title}</p>
                    <p className="text-sm font-dmsans font-normal text-slate-400">{perks}</p>
                    <div className="flex justify-between items-center ">
                        <p className="font-black font-sharp">From &#8358;{price}.00</p>
                        <Link to={buttonLink} className="bg-green-500 text-white p-2 rounded-md" >Book Now</Link>
                    </div>
                </div>
    </div>
 }