import { Helmet } from "react-helmet-async";




export function SeoBasic({title,desc,canonHref}){
    return <Helmet>
                <title>{title}</title>
                <meta name="description" content={desc}></meta>
                <link rel="canonical" href={`https://www.requestmechanic.com/${canonHref}`} />
           </Helmet>
}

export function NoIndexSeo(){
    return <Helmet>
                <meta name="robots" content="noindex"></meta>
           </Helmet>
}