import React, { useState, useEffect,useContext } from 'react';
import AuthContext from "../Context/authContext";
import { Button } from "@mui/material";
import { Eye,EyeSlash,WalletAdd} from "iconsax-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { numberWithCommas } from "../Components/utils";

 

export default function WalletCard({actionText,actionLink}){
    const [balance,setBalance] = useState(0);
    const [shouldHideBalance,setHideBalance] = useState(false);
    const navigate = useNavigate()
    const hideValue = "XX XXX XXX";
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;
  

    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/wallets/balance/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    console.log(" wallet balance response",response);
                    setBalance(init=>response.data?.balance)
                    return response
                }).catch(function(error){
                    console.log(error);
                })
            }catch(error){
                console.log("an error occured while fetching profile data")
            }
        }
    },[])

    function handleActionClick(){
        navigate(`${actionLink}`)
    }

    function toggleBalanceVisibility(){
        setHideBalance(init=>!init);
    }
    return <div className="w-full h-[20vh] relative mb-4">
                <div className ="w-full h-full bg-gradient-to-r from-green-500 to-green-700 rounded-xl"></div>
                <div className="w-full h-full absolute top-0 left-0 p-6 flex flex-col justify-between">
                    <div className="text-white">
                        <div className="flex gap-2 text-sm "><p>Available Balance</p><div onClick={toggleBalanceVisibility}>{shouldHideBalance?<EyeSlash/>:<Eye/>}</div> </div>
                        <p className="text-[1.7rem] font-bold pt-1">{shouldHideBalance ?hideValue:`₦${numberWithCommas(balance || '00.00')}`}</p>
                    </div>
                    <Button onClick={handleActionClick} className="w-fit flex gap-2 py-2 px-2 font-bold text-md " sx={{borderRadius:"2rem",backgroundColor:"white",color:"green",textTransform:"none", fontWeight:"700"}} variant="contained"  ><WalletAdd/> {actionText}</Button>
                </div>
    </div>
}