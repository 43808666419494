import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faUser,
  faWrench,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import { UpdateLocationModal } from "./UpdateLocationModal";
import { UpdateSupportingDocumentModal } from "./UpdateSupportingDocumentModal";
import { UpdateTestEnvironmentModal } from "./UpdateTestEnvironmentModal";

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

const MechanicProfile = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [mechanicData, setMechanicData] = useState(null);
  const [modeData, setModeData] = useState(null);
  const [user, setUser] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  // const [mechanicDatax, setMechanicDatax] = useState(null);
  const { email } = useParams(); // Assuming you're using the email as a parameter
  const [errorMessage, setErrorMessage] = useState(""); // State to store error message
  const [successMessage, setSuccessMessage] = useState(""); // State to store success message
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [showTestModal, setShowTestModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(`${baseUrl}/user/user/${encodeURIComponent(email)}/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log(response.data);
          setMechanicData(response.data.data);
        })
        .catch((error) => {
          console.error("Error fetching mechanic data:", error);
        });
    } else {
      console.error("Authentication credentials were not provided.");
    }
  }, [email]); // Include userEmail in dependencies

  useEffect (() => {
    if (!mechanicData?.id) return;

    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(`${baseUrl}/client/admin/mechanic/${mechanicData.id}/mode/`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          setModeData(response.data.current_mode);
        })
        .catch((error) => {
          console.error("Error fetching mode", error);
        });
    }
  }, [mechanicData])

  console.log(modeData);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const verifyProfile = async (mechanicId) => {
    try {
      setIsLoading(true); // Set loading state to true
      const accessToken = localStorage.getItem("authAccessToken");
      const response = await axios.post(
        `${baseUrl}/user/registration/${mechanicId}/update-status/`,
        {
          status: "approved", // Set status to 'approved' as required by the API
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-CSRFToken": localStorage.getItem("X-CSRFToken"), // Include CSRF token if required by the API
          },
        }
      );
      console.log("Verification Response:", response.data);
      if (response.data.success) {
        setSuccessMessage(response.data.message); // Set success message
        setErrorMessage(""); // Clear any previous error message
      } else {
        setErrorMessage(response.data.message); // Set error message if verification fails for a reason other than "already approved"
      }
    } catch (error) {
      console.error("Verification Error:", error.response.data.message);
      setErrorMessage(error.response.data.message); // Set error message from the API response
    } finally {
      setIsLoading(false); // Set loading state to false after request completes
    }
  };

  return (
    <>
      <div className="overall-dashboard-div flex h-screen bg-white-100">
        <div className="hamburger-icon" onClick={toggleSidebar}>
          <FontAwesomeIcon icon={faBars} className="hamburger" />
        </div>

        <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

        <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
          <div className={`dashboard-content ${showSidebar ? "sidebar" : ""}`}>
            {showSidebar && (
              <div className="close-sidebar-button" onClick={toggleSidebar}>
                <FontAwesomeIcon icon={faTimes} className="close-icon" />
              </div>
            )}
            <TopHeader userEmail={userEmail} />

            <div className="p-8">
              <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
                Users {">"} <Link to="/mechanics">Mechanic</Link> {">"}{" "}
                {mechanicData
                  ? mechanicData.first_name + " " + mechanicData.last_name
                  : "Loading..."}
              </h2>
              <p className="dashboard-text mt-2 text-gray-400">
                Track and manage the total number of registered mechanics on the
                platform.
              </p>
            </div>

            <h1 className="text-lg font-semibold text-gray-800 ml-8">
              Mechanic's Profile
            </h1>

            {mechanicData && (
              <div className="flex flex-col md:flex-row justify-between px-8 mt-8 gap-y-3">
                <div className="bg-white border border-gray-500 p-4 rounded-lg w-full md:w-1/2">
                  <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-2xl text-white"
                    />
                  </div>
                  <div className="text-gray-800 mt-4">
                    <h3 className="text-lg font-bold text-gray-800">
                      Personal Information
                    </h3>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">First Name:</span>{" "}
                      {mechanicData.first_name}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Last Name:</span>{" "}
                      {mechanicData.last_name}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Email Address:</span>{" "}
                      {mechanicData.email}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Date of Birth:</span>{" "}
                      {mechanicData.date_of_birth}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Phone Number:</span>{" "}
                      {mechanicData.phone_number}
                    </p>
                    <p className="mb-2 mt-4">
                      <span className="font-semibold">Current Mode:</span>{" "}
                      {modeData || '-'}
                    </p>
                    <Location>
                      <p className="mb-2 mt-4">
                        <span className="font-semibold">Workshop Location</span>{" "}
                        {mechanicData.location} <span></span>
                      </p>
                      <span
                        className="update"
                        onClick={() => setShowUpdateModal(true)}
                      >
                        Update
                      </span>
                    </Location>
                  </div>
                </div>

                <div className="flex flex-col w-full md:w-1/2 md:ml-4 gap-3">
                  <div className="bg-white border border-gray-500 p-4 rounded-lg">
                    <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faWrench}
                        className="text-2xl text-white"
                      />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">
                      Car Speciality
                    </h3>
                    <div className="flex space-x-4 flex-wrap">
                      {mechanicData.car_speciality.map((speciality) => (
                        <div
                          key={speciality.id}
                          className=" rounded-md bg-gray-200 p-2 m-1 text-xs  "
                        >
                          {speciality.name}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="flex flex-col w-full  gap-3">
                    <div className="bg-white border border-gray-500 p-4 rounded-lg">
                      <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                        <FontAwesomeIcon
                          icon={faWrench}
                          className="text-2xl text-white"
                        />
                      </div>

                      <h3 className="text-xl font-semibold text-gray-800">
                        Services
                      </h3>

                      <div className="flex space-x-4 flex-wrap">
                        {mechanicData.services.map((speciality) => (
                          <div
                            key={speciality.id}
                            className=" rounded-md bg-gray-200 p-2 m-1 text-xs  "
                          >
                            {speciality.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className="bg-white border border-gray-500 p-4 rounded-lg">
                    <div className="user-icon-container p-2 rounded-full w-12 h-12 flex items-center justify-center">
                      <FontAwesomeIcon
                        icon={faFileAlt}
                        className="text-2xl text-white"
                      />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-800">
                      Uploaded Document
                    </h3>
                    <div className="flex gap-5 ">
                      <a
                        href={mechanicData.uploaded_document?.document_url} // Assuming 'supported_document' is the URL of the document
                        download // This attribute indicates that the resource should be downloaded when clicked
                        className="mt-2 text-[#245444] hover:underline cursor-pointer" // Style the link as needed
                      >
                        Download Document
                      </a>
                      <p
                        onClick={() => setShowDocumentModal(true)}
                        className="mt-2 text-[#245444] cursor-pointer" // Style the link as needed
                      >
                        Update Document
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="flex justify-end mt-8 pr-2">
              {mechanicData && (
                <>
                  <Center>
                    <button
                      className=" mb-8 bg-green-500 text-white px-4 py-2 rounded-md mr-4"
                      onClick={() => verifyProfile(mechanicData.id)}
                      disabled={isLoading}
                    >
                      {isLoading ? "Verifying..." : "Verify Profile"}
                    </button>
                    <button
                      className="button"
                      onClick={() => setShowTestModal(true)}
                    >
                      Set up environment
                    </button>

                    {successMessage && (
                      <p className="text-green-500 text-sm font-medium">
                        {successMessage}
                      </p>
                    )}
                    {errorMessage && (
                      <p className="text-red-500 text-sm font-medium">
                        {errorMessage}
                      </p>
                    )}
                  </Center>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <UpdateLocationModal
        showModal={showUpdateModal}
        setShowModal={setShowUpdateModal}
      />

      <UpdateSupportingDocumentModal
        showModal={showDocumentModal}
        setShowModal={setShowDocumentModal}
      />

      <UpdateTestEnvironmentModal
        showModal={showTestModal}
        setShowModal={setShowTestModal}
      />
    </>
  );
};

export default MechanicProfile;

const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .update {
    color: #245444;
    cursor: pointer;
    font-weight: 600;
  }
`;

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin: 0 2rem;

  .button {
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 2rem;
    color: rgb(34 197 94);
    border: 1px solid rgb(34 197 94);
  }
`;
