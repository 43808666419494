import {useEffect, useState} from "react"
import { Whatsapp,TickCircle,Play,CloseCircle } from "iconsax-react";
import logoImg from "../../Images/RM - Idan-03 1.svg";
import NavListHeader from "../../Components/DynamicHeader";
import { Link, useNavigate } from "react-router-dom";
import MobileMenu from "../../Components/mobileMenu";
import logoTxt from "../../Images/Request Mechanic.png"
import footerLogo from "../../Images/Frame 1000000874.png";
import {
    FiFacebook,
    FiTwitter,
    FiInstagram,
    FiArrowRight,
    FiPlus,
    FiMinus,
  } from "react-icons/fi";
import { motion } from "framer-motion"
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';


import { TermsModal } from "../../Components/modal/termsModal";
import { contractTypeConstant } from "../../Home";

import heroImage from "../../Images/temporary/Final-HERO-2024.jpg"
import serviceImage from "../../Images/temporary/ext-wash-p-1080.jpg"
import watchus from "../../Images/temporary/watchus.jpg"
import one from "../../Images/temporary/1.jpg"
import two from "../../Images/temporary/2.jpg"
import three from "../../Images/temporary/3.jpg"
import four from "../../Images/temporary/4.jpg"
import five from "../../Images/temporary/5.jpg"
import six from "../../Images/temporary/6.jpg"
import seven from "../../Images/temporary/7.jpg"
import detail from "../../Images/temporary/detail.png"

import axios from "axios";



export function Header(){
    const navigate = useNavigate()
    return <nav className="sticky z-50 top-0 left-0 w-full  bg-black px-[25px] sm:px-[50px] md:px-[100px] py-[30px] flex flex-row items-center justify-between border-b border-b-gray-900">
            <div className="flex gap-10">
                <div onClick={()=>navigate("/")} className="flex gap-1">
                    <img className="object-contain h-8" src={logoImg} alt="Request Mechanic"/>
                    <img className="object-contain h-8" src={logoTxt} alt="" />
                </div>
                <NavListHeader />
            </div>
            <Link to={"/onboarding"} className="request-nav-button flex items-center gap-2 text-black text-sm py-2 px-3 w-fit flex-grow-0">
                Request Now
                <FiArrowRight    className="FiArrowRight"    style={{ color: "black", height: "20px", width: "20px" }}/>
            </Link>
            <MobileMenu />
        </nav>
}

export function Body(){

    const navigate = useNavigate();
    const [showQuoteForm, setShowQuoteForm] = useState(false);

    function showForm(){
        setShowQuoteForm(()=>true)
    }

    function closeForm(){
        setShowQuoteForm(()=>false)
    }

    return <div className="w-full  overflow-hidden h-[80vh] lg:h-[110vh] relative flex items-center justify-center ">
                <div className=" z-0 absolute top-0 left-0 w-full h-full overflow-hidden">
                    <img src={heroImage} className="object-cover h-[100%] lg:h-auto  "/>
                </div>
                <div  className="w-full h-[100%] lg:h-auto  z-10 relative flex flex-col justify-around  lg:gap-3 items-center text-black ">
                    <div className="w-full flex flex-col-reverse lg:flex-col lg:gap-3 items-center ">
                        <p className="text-green-700 lg:text-black">Get 5-star Service at Home or At Our Shop</p>
                        <p className="hidden lg:block text-center lg:text-left w-fit lg:w-[50%] text-3xl text-wrap  lg:text-6xl font-dmsans font-black">Professional Car Detailing Services in Lagos</p>
                        <p className="text-xl lg:text-md font-sharp lg:font-dmsans text-black lg:text-slate-700 font-extrabold lg:font-black">Make Your Car Shine Like Say Na New One</p>
                    </div>
                    <div className="flex flex-col lg:flex-row  gap-4 justify-center lg:justify-start">
                        <button onClick={()=>{navigate("book")}} className="bg-green-500 text-white py-2 px-3 rounded-md">Book Your Wash</button>
                        <button  onClick={showForm} className="bg-white py-2 text-black px-3 rounded-md">Get a Free Quote</button>
                    </div>
                </div>
                {showQuoteForm && <FreeQuoteForm closeForm={closeForm} />}
    </div>
}

export function Services(){
    const [currentTab,setCurrentTab] = useState(1);
    function changeCurrentTab(value){
        setCurrentTab(()=>value);
    }
    const services = {
        1:[
            {title:"Silver Full Detail",interiorPerks:["Exterior Wash", "tire cleaning" ," basic interior vacuum"],ExteriorPerks:["Exterior Wash", "tire cleaning" ," basic interior vacuum"],description:"Our Refresh Package. Best for maintenance or quick refresh.",serviceImg:serviceImage,catColor:"text-red-600",category:"refresh"},
            {title:"Gold Full Detail",interiorPerks:["Double Vacuum Interior","Wipe all surfaces","Stains (Spot Treatment)","Windows & Mirrors","Clean & Protect Plastic","Detail Floor Mats and Shine","Detail Trunk & Door Jambs",],ExteriorPerks:["Spot Polish","Professional Hand Wash","Clay Bar Exterior","Detail Rims & Tires","Wheel Wells","Wax Protection (3 Months)",],description:"Our Standard Package. A very thorough inside-out detail.",serviceImg:serviceImage,catColor:"text-red-600",category:"Most Popular"},
            {title:"Diamond Full Detail",interiorPerks:["Shampoo Seats & Carpet","Double Vacuum Interior","Wipe all Surfaces","Stain (Spot Treatment)","Clean & Protect Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],ExteriorPerks:["Exterior Wash", "tire cleaning" ," basic interior vacuum"],description:"Ultimate Detail Experience. Includes Full Polish & Shampoo/Extraction.",serviceImg:serviceImage,catColor:"text-red-600",category:"Vip"},
        ],
        2:[
            {title:"Classic Exterior",perks:["Basic Spot Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail & Mirrors","Wax Protection (3 Months)",],interiorPerks:[],ExteriorPerks:[],description:"Our Classic Package. Best for maintenance or quick refresh.",serviceImg:serviceImage,catColor:"text-red-600",category:"Classic"},
            {title:"Wax & Buff",perks:["1-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],interiorPerks:[],ExteriorPerks:[],description:"This Package will remove light swirls and scratches.",serviceImg:serviceImage,catColor:"text-red-600",category:"Popular"},
            {title:"Cut & Polish",perks:["2-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],interiorPerks:[],ExteriorPerks:[],description:"This Package will remove deep swirls and scratches.",serviceImg:serviceImage,catColor:"text-red-600",category:"Vip"},
        ],
        3:[
            {title:"Classic Interior",perks:["Complete Interior Vacuum","Wipe All Surface","Stains (Spot Treatment)","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],interiorPerks:[],ExteriorPerks:[],description:"Good for maintaining your vehicle interior.",serviceImg:serviceImage,catColor:"text-red-600",category:"Classic"},
            {title:"Deep Clean",perks:["Deep Cleaning Treatment","Shampoo All Carpets & Seats","Double Vacuum Interior","Stain Extraction","Clean & Protect All Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],interiorPerks:[],ExteriorPerks:[],description:"Includes seat & carpet shampoo and conditioner.",serviceImg:serviceImage,catColor:"text-red-600",category:"Popular"},
        ]
    }

    return <div className="lg:py-12 w-full overflow-x-hidden flex flex-col items-center">
                <p className="font-sharp mb-3 font-extrabold text-4xl text-center mt-8 ">Book A Detail With Us</p>
                <DetailNavBar currentTab={currentTab} changeCurrentTab={changeCurrentTab} />
                <div className="flex flex-wrap w-full p-8 px-12 justify-center gap-4" >
                    {
                        services[currentTab].map(function(aService){
                            return <AService perks={aService.perks} title={aService.title} description={aService.description} serviceImg={aService.serviceImg} catColor={aService.catColor} category={aService.category} interiorPerks={aService.interiorPerks} exteriorPerks={aService.ExteriorPerks} />
                        })
                    }
                </div>
    </div>
}

function DetailNavBar({currentTab,changeCurrentTab}){

    return <div className="w-[90%] bg-slate-200 rounded-xl p-2 flex justify-around items-center ">
                <ADetailNavBarItem currentTab={currentTab} identifier={1}  data={{handleClick:changeCurrentTab,value:"FULL DETAIL"}} />
                <ADetailNavBarItem currentTab={currentTab} identifier={2} data={{handleClick:changeCurrentTab,value:"EXTERIOR ONLY"}} />
                <ADetailNavBarItem currentTab={currentTab} identifier={3} data={{handleClick:changeCurrentTab,value:"INTERIOR ONLY"}} />
    </div>
}

function ADetailNavBarItem({data,identifier,currentTab}){
    return <div onClick={()=>data.handleClick(identifier)} className={`${identifier === currentTab && " bg-green-600 text-white shadow-sm shadow-black"} flex-1 py-3 rounded-md text-black text-sm font-extrabold hover:cursor-pointer text-center`}>
                    {data.value}
            </div>
}

function AService({title,category,catColor,perks=[],interiorPerks,exteriorPerks,serviceImg,description}){
    const navigate = useNavigate();

    function handleButtonClick(){
        navigate("/detailing/book")
    }

    return <div className="w-[100%] lg:w-[30%]  border-t-[0.4rem] border-green-300 p-4 text-black border-solid bg-white rounded-lg">
                <div className="flex gap-[1.5rem]">
                    <p className="text-2xl font-dmsans font-extrabold">{title}</p>
                    <p className={`font-axiforma text-sm ${catColor}`}>{category}</p>
                </div>
                <div className="w-full mb-3">
                    <img src={serviceImg} alt="depiction of full detailing" className="w-full h-auto rounded-md" />
                </div>
                <p className="text-sm mb-8">{description}</p>

                <p className="font-extrabold text-lg my-2" >{(perks?.length > 0) ? "What's Included":"Interior Perks"}</p>
                {(perks?.length < 1) && <div>
                        {interiorPerks?.map(function(aPerk){
                            return  <div className="flex items-center "><TickCircle className="text-green-600" /> <p>{aPerk}</p></div>
                        })}
                        {perks?.map(function(aPerk){
                            return  <div className="flex items-center "><TickCircle className="text-green-600" /> <p>{aPerk}</p></div>
                        })}
                </div>}
                {(perks?.length < 1) && <p  className="font-extrabold text-lg my-2" >Exterior Perks</p>}
                {(perks?.length < 1) && <div>
                    {exteriorPerks.map(function(aPerk){
                        return <div className="flex items-center "><TickCircle className="text-green-600" /> <p>{aPerk}</p></div>
                    })}
                </div>}
                {
                    (perks?.length > 0) &&<div>
                    {perks.map(function(aPerk){
                        return <div className="flex items-center "><TickCircle className="text-green-600" /> <p>{aPerk}</p></div>
                    })}
                </div>
                }
                <button onClick={handleButtonClick} className="bg-green-400 text-black font-bold mt-4 py-3 px-8 rounded-md shadow-md " >Book Now</button>
    </div>
}

export function WatchIsWork(){
    return <div className="w-full  lg:h-[95vh] relative">
                <div className="w-full h-full overflow-hidden">
                    <img src={watchus} className="w-full object-cover" alt="depiction of us detailing a car" />
                </div>
                <div  className="bg-[rgba(0,0,0,0.3)] absolute top-0 left-0 w-full h-full overflow-hidden flex flex-col gap-8 items-center justify-center ">
                    <div className="flex gap-2 flex-col items-center justify-center">
                        <p className="font-sharp font-extrabold text-4xl">HOW WE DETAIL</p>
                        <p className="font-sharp font-extrabold text-2xl text-green-300">WATCH US WORK</p>
                    </div>
                    <div  className="flex gap-2 flex-col items-center justify-center">                        
                        <p className="font-sharp font-extrabold text-xl">2 min video</p>
                        <p className="font-sharp text-xl w-[95%] lg:w-[80%] text-center">See what makes RequestMechanic the top choice for everything car detailing. At Home or At Shop service available.</p>
                    </div>
                    <button className="mt-4 flex gap-2 py-2 px-4 rounded-xl bg-white text-black font-bold">Watch <Play className="text-black " variant="TwoTone" /></button>
                </div>
            </div>
}


export function CarLove(){
    return <div className="hidden w-full lg:flex flex-col items-center justify-center p-8 mt-8 ">
                <p className="text-5xl font-sharp font-[900]">Show your car some love.</p>
                <p className="text-slate-400 text-center w-[80%] font-sharp">Get service at our shop or at home with our mobile detailing service. All our detailers passed background checks, are friendly and reliable. Only thing we need is access to water & electricity. Fair pricing and 5-Star Service is what makes us Portland’s Top Choice.</p>
                <div className="mt-4 grid grid-cols-4 w-[90%] gap-4" >
                    <img src={watchus} />
                    <img src={one} />
                    <img src={two} />
                    <img src={three} />
                    <img src={four} />
                    <img src={five} />
                    <img src={six} />
                    <img src={seven} />
                </div>
    </div>
}

export function Recommendations(){
    const listOfRecommendations = [
                                    {tiDesc:"MOST POPULAR",Title: "CAR DETAILING",image:detail,perks:["Interior & Exterior Detailing","Deep Cleaning + Hand Wash","Interior starting at  ₦282,000"]},
                                    {tiDesc:"SCRATCH REMOVAL",Title: "PAINT POLISH",image:detail,perks:["Scratch + Swirl Removal","Paint Correction + Wax","Starting price at ₦227,000",]},
                                    {tiDesc:"NEVER WAX AGAIN",Title: "CERAMIC COATING",image:detail,perks:["Permanent Protection","10 Year Warranty","Registers to CARFAX",]},
                                ]
    return <div className="w-full grid lg:grid-cols-3 p-12 gap-6 ">
                {
                    listOfRecommendations.map(function(data){
                        return <ARecommendation tiDesc={data.tiDesc} Title={data.Title}  perks={data.perks} image={data.image} />
                    })
                }
    </div>
}

function ARecommendation({tiDesc,Title,image,perks}){
    const navigate = useNavigate();

    function handleButtonClick(){
        navigate("/detailing/book")
    }

    return <div className="p-3 text-black gap-2 flex flex-col items-center justify-center font-sharp bg-white rounded-xl">
                <p className="font-bold text-slate-600">{tiDesc}</p>
                <p  className="font-[900] text-3xl">{Title}</p>
                <div className="w-[60%]">
                    <img src={image} />
                </div>
                <div>
                    {perks.map(function(aPerk){
                        return <div className="flex items-center "><TickCircle className="text-green-600 text-xs" /> <p>{aPerk}</p></div> 
                    })}
                </div>
                <button onClick={handleButtonClick} className="bg-green-400 text-black font-bold mt-4 py-3 px-8 rounded-md shadow-md " >Book Now</button>
    </div>
}

export function Comparison(){
    const topics = ["Carbon Footprint","Ease of Use","Quality","Products","Clay Bar","Interior Clean","Exterior Clean"];
    const handWash = ["✔️ Only 19 Liters of water per wash","✔️ We come to your Home or Office","✔️ 5-Star Service and 100% Satisfaction","✔️ Locally sourced high quality products","✔️ Clay bar decontamination every time","✔️ Complete interior service + shampoo","✔️ Like New, Showroom quality finish"];
    const tunnelWash = ["❌ Up to 187 Liters of water per wash","❌ Customers have to drive and wait","❌ Average service and bad quality work","❌ Uses cheap chemicals bought in bulk","❌ No clay bar used ever","❌ No work performed on car's interior","❌ Scratches and swirls all over vehicle"];
    return <div className="hidden w-full lg:flex flex-col gap-6 items-center justify-center">
        
                <p className=" text-white text-4xl font-extrabold ">Car Wash vs. RequestMechanic</p>
                <p className=" w-[85%] text-center text-slate-400 ">Our expert detailers provide a hand wash and wax that goes far beyond a typical tunnel wash. Our in–depth clean removes dirt, stains, and scratches that may have built up over time. The result? A sparkling clean car that looks its absolute best.</p>
                <div className="grid grid-cols-3 w-[90%] items-end ">
                    <div className="">
                            {topics.map(function(reason){
                                return <p className="pl-4 bg-green-100 border-[0.02rem] p-2  text-black border-solid border-green-800">{reason}</p>
                            })}
                        </div>
                    <div>
                        <p className="pl-4 bg-green-400 border-[0.02rem] p-2 text-white text-3xl font-extrabold border-solid border-green-800">Tunnel Wash</p>
                        <div>
                            {tunnelWash.map(function(reason){
                                return <p className="pl-4 bg-green-100 border-[0.02rem] p-2 text-black border-solid border-green-800">{reason}</p>
                            })}
                        </div>
                    </div>
                    <div>
                        <p className="bg-teal-600 border-[0.02rem] p-2 text-white text-3xl font-extrabold border-solid border-green-800">Our Hand Wash</p>
                        <div>
                            {handWash.map(function(reason){
                                return <p className="bg-green-100 border-[0.02rem] p-2 text-black border-solid border-green-800">{reason}</p>
                            })}
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
    </div>
}

export  function Faq(){    
    const questions = [
      {
        id: 1,
        question: "What does RequestMechanic do?",
        answer:
          "RequestMechanic is a mobile application designed to lower the entry barrier into the mechanic industry by providing a platform for regular car owners to engage with mechanics",
      },
      {
        id: 2,
        question: "Can I trust the mechanic, is the mechanic a  specialist mechanic?",
        answer:
          "Yes, the automobile mechanic is a specialist mechanic specific to your vehicle’s brand and model.",
      },
      {
        id: 3,
        question: "Can I speak to a specialist mechanic at any time, 24/7 service?",
        answer:
          "Yes. This is an immediate service. specialist mechanics are available as seen on the app.",
      },
    ];

  return  <div  id="FAQs" className="ml-4 mr-4 lg:mr-0 lg:ml-16 mt-16 w-[90%] lg:w-[45%] max-h-[400px] overflow-y-auto">
              {questions.map((faq) => (
                  <SingleFaq id={faq.id} question={faq.question} answer={faq.answer}  />
              ))}
</div>
} 

function SingleFaq({id,question,answer}){
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const toggleExpansion = (id) => {
      setExpandedQuestion((prevId) => (prevId === id ? null : id));
    };
    const animation = {
      initial:{
          zIndex:-1,
          opacity:0,
          transform:"translateY(-10%)",
          display:"none"
      },
      slideOut:{
          display:"block",
          zIndex:-1,
          opacity:1,
          transform:"translateY(0%)",
          transition:{
              duration:0.8
          },
      }
    }

  return <div key={id} className="border-b border-t border-dark">
              <button className="w-full text-white text-sm flex items-center justify-between cursor-pointer py-2 pr-3" onClick={() => toggleExpansion(id)} >
              <p className="text-white text-sm text-left font-medium">{question}</p>
              <p>
                  {expandedQuestion === id ? (
                  <FiMinus className="h-4 w-4 text-gray-500" />
                  ) : (
                  <FiPlus className="h-4 w-4 text-gray-500" />
                  )}
              </p>
              </button>
  {expandedQuestion === id && (
    <motion.div className="text-sm text-gray-300 font-extralight py-3" variants={animation} initial="initial" animate = {expandedQuestion?"slideOut":""}  >{answer}</motion.div>
  )}
</div>
}

export function Footer1(){
    return <div className="w-full bg-slate-200 p-12 text-sm text-black flex flex-col items-center justify-center   ">
                <div className="flex gap-4 lg:gap-0 flex-col lg:flex-row font-dmsans w-full justify-around">
                    <div className="flex flex-col items-center">
                        <p className="font-dmsans mb-2 font-black text-lg">RequestMechanic</p>
                        <p>Lagos State, Nigeria.</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="font-dmsans mb-2 font-black text-md">Quick Links</p>
                        <p>Home</p>
                        <p>Services</p>
                        <p>Book Now</p>
                    </div>
                    <div className="flex flex-col items-center">
                        <p className="font-dmsans mb-2 font-bold text-md">Contact Us</p>
                        <p className="flex gap-1"><Whatsapp/>Chat on WhatsApp</p>
                    </div>
                </div>
                <p>&copy; 2025 RequestMechanic. All rights reserved.</p>
    </div>
}

export function Footer(){
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState("");
    const [contractType,setContractType] = useState('');
    const navigate = useNavigate();

    const closeModal = () => {
        setIsModalOpen(false);
        setModalType("");
        setContractType(init=>"")
        navigate(window.location.pathname);
    };

    const openModal = (mType,contractType) => {
    setIsModalOpen(true);
    setModalType(mType);
    setContractType(init=>contractType)

    if (mType === "pp") {
        navigate("#privacy-policy");
    } else if (mType === "tnc") {
        navigate("#terms-and-condition");
    }
    };


    return  <footer className="bg-black pl-6 lg:pl-24 text-white py-8 mt-32">
        <div className="grid gap-10 xs:grid-cols-1 sm:grid-cols-3 lg:grid-cols-4">
        <div className="sm:flex sm:flex-col sm:items-start">
            <img    src={footerLogo} alt="Footer Logo" className="mb-4 cursor-pointer" />
            <p className="footer-text">RequestMechanic is a mobile application that provides you one-click instant access to certified mechanics and auto-experts
            </p>
        </div>

        <div className="sm:text-center flex flex-col sm:items-center">
            <p className="font-bold cursor-pointer">Company</p>
            <a
            href="/#features-section"
            className="text-white underline-offset-1 text-xs mt-3 mb-2"
            >
            Features
            </a>
            <a
            href="/detailing"
            className="text-white underline-offset-1 text-xs mt-3 mb-2"
            >
            Services
            </a>
            <a
            href="https://medium.com/@ochuko_34353"
            className="text-white underline-offset-1 text-xs mt-3 mb-2" target="_blank"
            >
            Blog
            </a>
            <a
            href="/#support-section"
            className="text-white underline-offset-1 text-xs my-2"
            >
            Support
            </a>


            <button
            onClick={(e) => openModal("pp",contractTypeConstant.Customer)}
            className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
            Customer's Privacy Policy
            </button>
            <button
            onClick={(e) => openModal("tnc",contractTypeConstant.Customer)}
            className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
            Customer's Terms & Conditions
            </button>
            <button
            onClick={(e) => openModal("pp",contractTypeConstant.Mechanic)}
            className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
            Mechanic's Privacy Policy
            </button>
            <button
            onClick={(e) => openModal("tnc",contractTypeConstant.Mechanic)}
            className="text-white underline-offset-1 text-xs my-2 cursor-pointer w-fit"
            >
            Mechanic's Terms & Conditions
            </button>
            <TermsModal
            isOpen={isModalOpen}
            onClose={closeModal}
            modalType={modalType}
            userType={contractType}
            />
        </div>

        <div className="text-center flex flex-col items-center">
            <p className="font-bold cursor-pointer">Contact</p>
            <a
            id="support-section"
            href="mailto:Support@requestmechanic.com"
            className="email-link my-3"
            >
            <p className="email-link">Support@requestmechanic.com</p>
            </a>
            <div className="flex gap-8">
            <a href="https://www.facebook.com/profile.php?id=100095295696587&mibextid=LQQJ4d">
                <FiFacebook className="text-white text-xl cursor-pointer" />
            </a>
            <a href="https://twitter.com/requestmechanic?s=11">
                <FiTwitter className="text-white text-xl cursor-pointer" />
            </a>
            <a href="https://instagram.com/requestmechanic?igshid=MzRlODBiNWFlZA==">
                <FiInstagram className="text-white text-xl cursor-pointer" />
            </a>
            </div>
        </div>
        </div>
  </footer>
}

export function FreeQuoteForm({closeForm,location}){
    const ants = {default:'default',error:'error',success:'success',loading:'loading',formError:"field-incompletely-filled"};
    const [requestState,setRequestState] = useState(ants.default);
    const [clientsName,setClientsName] = useState("");
    const [service,setService] = useState("");
    const [phoneNumber,setPhoneNumber] = useState("");
    const navigate = useNavigate();


    function allFieldsAreFilled(){
        return ((clientsName != "") && (service != "") && (phoneNumber != "") );
    }
    function clearFields(){
        setClientsName(()=>"");
        setPhoneNumber(()=>"");
        setService(()=>"");
    }
    function SubmitForm(){
        if(allFieldsAreFilled()){
            setRequestState(()=>ants.loading);
            axios.post("https://formsubmit.co/ajax/okiemuteonojakpor@gmail.com",
            {
            clientsName,
            service,
            phoneNumber 
            },
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
            ).then(function(response){
                setRequestState(()=>ants.success);
                return response
            })
            .catch(function( error){
                setRequestState(()=>ants.error);
                return error
            })
            .finally(()=>{
                setTimeout(() => {
                    setRequestState(()=>ants.default);
                }, 2000);
                clearFields()
                navigate("/detailing/thanks")
            });
        }else{
            setRequestState(()=>ants.formError)
        }
    }
    function handleInputChange(setter,value){
        setter(()=>value)
    }

    useEffect(function(){
        {
            switch (requestState) {
                case ants.error:toast.error("There was an error requesting a toast")
                    break;
                case ants.formError:toast.warn("Please fill all the fields")
                    break;
                case ants.success:toast.success("We will send you a quote via email or whatsapp")
                    break;
            
                default:
                    break;
            }
        }
    },[requestState])

    return <div className="z-20 absolute w-[90%] p-4 rounded-lg lg:w-[60%] bg-white  text-black">
                <div className="w-full flex justify-between font-[900] mb-4">
                    <p className="w-full text-2xl">Get Free Quote</p>
                    <CloseCircle onClick={closeForm} />
                </div>
                <div className="flex flex-col gap-3  text-black ">
                    <div className="flex flex-col ">
                        <label>Your Name*</label>
                        <input value={clientsName} onChange={(e)=>handleInputChange(setClientsName,e.target.value)} name="Clients Name" className="bg-green-200 text-green-500 p-2"  />
                    </div>
                    <div className="flex flex-col ">
                        <label>Select A Service*</label>
                        <select value={service} onChange={(e)=>handleInputChange(setService,e.target.value)} className="bg-green-200 text-green-500 p-2">
                            <option>Car Detailing</option>
                            <option>Window Tinting</option>
                            <option>Ceramic Coating</option>
                            <option>Paint protection Film</option>
                        </select>
                    </div>
                    <div className="flex flex-col ">
                        <label>Your Phone Number*</label>
                        <input value={phoneNumber} onChange={(e)=>handleInputChange(setPhoneNumber,e.target.value)} name="Clients Name" className="bg-green-200 text-green-500 p-2"  />
                    </div>
                </div>
                <button onClick={SubmitForm}  disabled={requestState == ants.loading} className={`${requestState == ants.loading?"bg-slate-800":"bg-black"}  text-white w-full py-2 rounded-xl mt-6`} >{(requestState == ants.loading)?"Loading...":"Continue"}</button>
            </div>
}



