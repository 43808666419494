import { ArrowLeft2 } from "iconsax-react";
import React, { useState, useEffect,useContext } from 'react';
import AuthContext from "../Context/authContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";


 

export default function Withdraw(){
    const [banks,setBanks] = useState([]);
    const [accountName ,setAccountName] = useState(null);
    const [accountNumber ,setAccountNumber] = useState(null);
    const [amount ,setAmount] = useState(null);
    const [selectedBankCode ,setselectedBankCode] = useState(null);
    const [Continue,setContinue] = useState(false);
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;
  

    function handleBack(){
        window.history.back()
        console.log("back");
    }

    function handleAccountNumberChange(value){
        setAccountNumber(init=>value);
    }

    function handleBankSelect(value){
        setselectedBankCode(init=>value);
        console.log("bank code",value);
    }

    function handleAmountChange(value){
        setAmount(int=>value)

    }

    function handleContinueClick(){
        if((accountNumber.length === 10) && selectedBankCode !== null && Number(amount) >=500 ){
            setContinue(init=>true);
        }
    }

    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/wallets/banks/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    console.log("get list of banks response",response.data.data);
                    setBanks(init=>response.data.data)
                    return response
                }).catch(function(error){
                    console.log(error);
                })
            }catch(error){
                console.log("an error occured while fetching profile data")
            }
        }
    },[]);
    
    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken && (selectedBankCode !== null) && (String(accountNumber).length === 10)) {
            setAccountName(init=>"Getting Name")
            try{
                axios.get(`${baseUrl}/wallets/banks/resolve-bank-account/${accountNumber}/${selectedBankCode}`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                })
                .then(function(response){
                    console.log("get account name response",response);
                    setAccountName(init=>response?.data?.data?.account_name)
                    return response
                }).catch(function(error){
                    setAccountName(init=>"error")
                    console.log(error);
                })
            }catch(error){
                setAccountName(init=>"error")
                console.log("an error occured while fetching profile data")
            }
        }
    },[selectedBankCode,accountNumber]);

    return <div className=" relative h-screen w-screen p-4">
                <div className="mb-4 flex text-xl items-center"><ArrowLeft2 onClick={handleBack}/> <p className="font-bold">Withdrawal</p></div>
                <div className="w-full flex flex-col gap-4 ">
                    <div className="w-full relative ">
                        <p className="mb-2 text-md">Bank Name</p>
                        <select onChange={(e)=>handleBankSelect(e.target.value)} className="font-sharp rounded-md w-full block outline-none  p-2 py-3 border-solid border-[0.02rem] border-slate-500" placeholder="Select a Bank">
                            {banks.length ==0 && <option>Getting Banks</option>}
                            {
                               banks.map(function(abank){
                                   return <option value={abank.code} >{abank.name}</option>
                               })
                            }
                        </select>

                    </div>
                    <div>
                        <p  className="mb-2 text-md">Bank Account</p>
                        <input onChange={(e)=>handleAccountNumberChange(e.target.value)} type="number" className="font-sharp rounded-md w-full block outline-none  p-2 py-3 border-solid border-[0.02rem] border-slate-500" placeholder="02346255177"/>
                        <p>{accountName}</p>
                    </div>
                    <div>
                        <p  className="mb-2 text-md">Amount</p>
                        <input onChange={(e)=>handleAmountChange(e.target.value)} type="number" className="font-sharp rounded-md w-full block outline-none  p-2 py-3 border-solid border-[0.02rem] border-slate-500"  placeholder="500.00"/>
                    </div>
                    <button onClick={handleContinueClick} className="w-full bg-green-500 rounded-md py-2 text-white">Continue</button>
                </div>
                {Continue && <div onClick={()=>setContinue(init=>false)} className="w-screen h-screen bottom-0 bg-opacity-30 left-0 absolute bg-slate-600"></div>}
                {Continue && <Confirmation amount={amount} accountName={accountName} accountNumber={accountNumber} selectedBankCode={selectedBankCode}/>}
            </div>
}

function Confirmation({amount,accountName,accountNumber,selectedBankCode}){
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;
    const [password,setPassword] = useState(null);
    const navigate = useNavigate()
    function handleWithdrawClick(){
        const accessToken = localStorage.getItem('authAccessToken')
        if(accessToken && (accountName!==null) && (accountNumber !== null) && ( selectedBankCode!== null)){
                axios.post(`${baseUrl}/wallets/banks/transfer-recipient/`,{
                    account_name: accountName,
                    account_number:accountNumber,
                    bank_code:selectedBankCode,
                },{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type':'application/json'
                    }
                })
                .then(function(response){
                    if(accessToken && (accountNumber!==null) && (amount !== null) && ( selectedBankCode!== null)){
                    axios.post(`${baseUrl}/wallets/withdraw/`,{
                        password:password,
                        amount:amount,
                        bank_id:Number(selectedBankCode),
                    },{
                        headers:{
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type':'application/json'
                        }
                    }
                ).then(function(response){
                    navigate("/withdraw")
                })
                .catch(function(error){
                        console.log(error);
                        return error
                    });
                    return response
                }}).catch(function(error){
                    console.log(error);
                })
        }

    }
    function handlePasswordChange(value){
        setPassword(init=>value)
    }
    return <div className=" bg-green-100 p-4 absolute left-0 bottom-0 w-screen rounded-t-3xl">
                <p className="font-bold text-xl">Enter your password</p>
                <p className="text-slate-500 mt-2">For addd security</p>
                <p className="mt-4">Password</p>
                <input onChange={(e)=>handlePasswordChange(e.target.value)} className="mt-2 outline-none border-solid border-slate-500 border-[0.02rem] w-full p-3 rounded-md block" type="password" placeholder="******"/>
                <button onClick={handleWithdrawClick} className="w-full bg-green-500 rounded-md py-2 mt-3 text-white">Continue</button>
    </div>
}