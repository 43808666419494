import { createContext } from "react";


const hostname = window.location.hostname;
const isProd = hostname.includes("requestmechanic");
function getBaseUrl() {
    return isProd 
      ? "https://requestnowmechanic.com/v1" 
      : "https://requestnowmechanic.com/staging/v1";
}

export const authContextDefaultValue = {
    baseUrl : getBaseUrl(),
    accessToken : localStorage.getItem('authAccessToken'),
    userEmail : localStorage.getItem('userEmail'),
    refreshToken : localStorage.getItem('authRefreshToken'),
    UserEmail: localStorage.getItem('userEmail'),
    FirstName: localStorage.getItem('firstName'),
    LastName: localStorage.getItem('lastName'),
    AccountType : localStorage.getItem('accountType'),
    ownerToken : localStorage.getItem("deviceToken"),
    isProd:isProd,
}

const AuthContext  = createContext(authContextDefaultValue)

export default AuthContext;