import { Call } from "iconsax-react"
import {useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import React, { useState, useEffect,useContext,useRef } from 'react';
import AuthContext from "../../Context/authContext";
import callTone from "../../audio/pickup.mp3"
import axios from "axios";
const authToken = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyMjkxMzI4MSwiZXhwIjoxNzIzNTE4MDgxfQ.9nXOq3YCfhafg9B9CTzQAbpfLpwyhBFbRKk2AmwteH8"


 

export  function AudioControls({duration,call_id,meetingId,mechanic_id,callerToken,back}){
  const {leave,join,end} = useMeeting({onParticipantLeft,onParticipantJoined,onMeetingStateChanged,onMeetingJoined});
  const [callPicked,setCallPicked] = useState(false);
  const [ring,setRing] =  useState(new Audio(callTone));
  const intervalRef = useRef(null);
  const bufferTime = 5000;
  const genData = useContext(AuthContext);
  const baseUrl = genData.baseUrl;
  // const isProd = genData.isProd;
  
  function hangup(){
    ring.pause();
    hangupNotifier();
  }
  function indicate_call_end_to_server(){
    const accessToken = localStorage.getItem("authAccessToken");
    if(accessToken){
      axios.post(`${baseUrl}/calls/end/`,{
        room_id:meetingId
        },{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
      }).then(function(response){
              console.log(response);
      }).catch(function(error){
            console.log("an error occured in the indicate_call_end_to_server function");
            return error
      })
    } 
  }
  function hangupNotifier(){
    const mechToken = localStorage.getItem("deviceToken");
    const accessToken = localStorage.getItem('authAccessToken');
    const lastName = localStorage.getItem("lastName");
    const firstName = localStorage.getItem("firstName");
      if(accessToken){
        axios.post(`${baseUrl}/client/send-message/`,{
            ownerName:`${firstName} ${lastName}`,
            call_token:authToken,
            meeting_id:meetingId,
            owner_fcm:mechToken,
            fcm_token:callerToken,
            is_call:false,
            is_voice:false,
            is_cancelled_call:true
      },{
          headers:{
              'Authorization':`Bearer ${accessToken}`,
              'Content-Type':'application/json'
          }
      }).then(function(result){
          back()
        return result
      }).catch(function(error){
        back()
        console.log("notification endpoint call error",error);
        return error
      })
    }
  }
  function handleCallEnded(){
      hangupNotifier();
  }
  function pickCall(){
    ring.pause()
    join();
    setCallPicked(init=>true);
  }

  function onMeetingJoined(){
    indicate_call_answered_to_server()
  }

  function indicate_call_answered_to_server(){
    const accessToken = localStorage.getItem("authAccessToken");
  if(accessToken){
    axios.patch(`${baseUrl}/calls/${call_id}/update/`,{
        status:"answered"
      },{
        headers:{
          'Authorization':`Bearer ${accessToken}`,
          'Content-Type':'application/json'
        }
    }).then(function(response){
            console.log(response);
    }).catch(function(error){
          console.log("an error occured in the indicate_call_answered_to_server function");
    })
  } 
}

  function onParticipantJoined(){

  }
  function onParticipantLeft(){
    // end();
    // indicate_call_end_to_server(meetingId);
    // window.history.back();
  }
  function onMeetingStateChanged(data){
    const {state} = data;
    switch(state){
      case 'DISCONNECTED':back();
      break;
      case 'CLOSING':back();
      break;
    }
  }

  function handleCallCanceled(){
    ring.pause();
    new Notification("Call was canceled")
    back()
  }

  useEffect(function(){
    if ( ring  instanceof HTMLAudioElement ) {      
      ring.play();
      ring.loop = true;
    }
    console.log("callDuration is",Number(duration)*60000);
  },[])

  useEffect(() => {
    document.addEventListener("canceled-call",handleCallCanceled)
    return function () {
      document.removeEventListener("canceled-call",handleCallCanceled)
    }
  })

  useEffect(()=>{
    if(callPicked){
      setTimeout(()=>{
        try{
          end();
          indicate_call_end_to_server();
        }catch(error){
          console.log(error);
        }finally{
          back()
        }
      }, (Number(duration)*60000)-bufferTime)
  }

  },[callPicked])

    return <div className={`w-[75%] flex ${!callPicked?"justify-between":"justify-center"} `}>
                { !callPicked && <div onClick={pickCall} className="bg-green-500 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                    <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]" />
                </div>}

                { !callPicked && <div onClick={function(){hangup()}} id="callender" className="bg-red-600 w-[5rem] h-[5rem] rounded-full flex justify-center items-center">
                    <Call variant="Bold" className="text-white h-[2.5rem] w-[2.5rem]" />
                </div>}
    </div>
}

export function Counter(){
const [seconds,setSeconds] = useState(0);
const [minute,setMinute] = useState(0);
const [hour,setHour] = useState(0);

function doubleFormat(value){
      if (String(value).length < 2) {
        return `0${value}`
      }else return value
}
    useEffect(function(){
      const interval = setInterval(function(){
          setSeconds(init=>{return init >= 59 ? 0:init +1})
      },1000)
  
      return function(){
        clearInterval(interval);
        
      }
    },[])
  
    useEffect(function(){
      const interval = setInterval(function(){
          setMinute(init=>{return init >= 59 ? 0:init +1}) 
            return function(){
              clearInterval(interval);
              
            }
      },60000);
    },[])
  
    useEffect(function(){
      const interval = setInterval(function(){
        setHour(init=>init++)
      },3600000)
  
      return function(){
        clearInterval(interval);
        
      }
    },[])
  
    return <div className="w-fit h-fit p-2 bg-slate-300 bg-opacity-60  rounded-lg">
      <p>{doubleFormat(hour)}:{doubleFormat(minute)}:{doubleFormat(seconds)}</p>
    </div>
}

export function Biodata({image,name,carmodel}){
    return <div className="flex flex-col justify-center gap-3 items-center">
                <div className="w-[8rem] h-[8rem] rounded-full overflow-hidden flex justify-center items-center">
                    <img src={image} />
                </div>
                <div className="flex flex-col gap-1 items-center justify-center">
                    <p className="font-bold text-2xl">{name}</p>
                    <p className=" text-slate-400 font-bold">{carmodel}</p>
                </div>
    </div>
}

export function AudioMents({startTimer}){
  const { participants,} = useMeeting({
    onMeetingJoined,
  });

  function onMeetingJoined(){
    startTimer()
  }

  useEffect(function(){
    console.log("keys",[...participants.keys()]);
   },[])

  const participantOne = useParticipant([...participants.keys()][0])
  const participantTwo = useParticipant([...participants.keys()][1])


  return <>
            {participantOne && <AnAudio participantId={[...participants.keys()][0]}/>}
            {participantTwo&& <AnAudio participantId={[...participants.keys()][1]}/>}
  </>
}

function AnAudio({participantId}){
  const { micStream, micOn, isLocal} = useParticipant(participantId);
  const micRef = useRef(null);
  const { leave, toggleMic, toggleWebcam,disableWebcam} = useMeeting();
  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  useEffect(function(){
    disableWebcam()
    console.log("disaable webcam was just called");
  },[])

  return  <audio ref={micRef} autoPlay playsInline muted={isLocal} />

}



