import React, { useState, useEffect,useContext, useMemo, useRef } from 'react';
import AuthContext from '../../Context/authContext';
import { Call,Message,More } from "iconsax-react";
import {MeetingProvider, useMeeting, useParticipant,} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import Controll_bg from "../../Images/call_control_bg.svg";
import SingleChat from "../chat/components/SingleChat";
import axios from "axios";
import Rating from "../../Components/rating"


 

export async function createMeeting(token){
  const res = await fetch(`https://api.videosdk.live/v2/rooms`, {
    method: "POST",
    headers: {
      'authorization': `${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  }).then(function(response){
          console.log(response)
          return response
  }).catch(function(error){
        console.log(error)
  })
  const { roomId } = await res?.json();
  return roomId;
};
  
export default function Callz({id,mechanicEmail,mechanicName,mechanic_id,token,carModel,profile_pic,mech_token}){
  const genData = useContext(AuthContext);
  const baseUrl = genData.baseUrl;
  // const isProd = genData.isProd;
  const [meetingId, setMeetingId] = useState(null);
  const [callDuration, setCallDuration] = useState(null);
  const [authToken, setAuthToken] = useState("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcGlrZXkiOiJmMDRmZDM1ZC00MzNlLTQzMmUtOGM0Yi1mNzdiNWIxZDI1YjEiLCJwZXJtaXNzaW9ucyI6WyJhbGxvd19qb2luIl0sImlhdCI6MTcyNjY3OTg2NywiZXhwIjoxNzU4MjE1ODY3fQ.o9w3qWNc3Y6caNnq5u6WAsc4Vx1l8sC6Pow9hhZtRnQ");
  const [Call_id,setCall_id] = useState("");
  const [showRating,setShowRating] = useState(false);
  const [notifcationPayload, setnotificationPayload] = useState({ownerId:"",
                                                               ownerName:"",
                                                               meeting_id:'',
                                                               call_token:'',
                                                               owner_fcm:"",
                                                               fcm_token:"",
                                                               is_call:true,
                                                               is_voice:false,
                                                               initiate_id:"",
                                                               owner_image:"",
                                                              //  owner_email:"",
                                                               email:"",
                                                               duration:"60",
                                                               model:carModel});
  // const [notifcationPayload, setnotificationPayload] = useState({id:"",model:carModel,email:"",image:profile_pic,name:"",type:"video"})
  const [shouldOpenChat,setShouldOpenChat] = useState(false);
  const [CarOwnerEmail,setCarOwnerEmail] = useState(localStorage.getItem("userEmail"));

  function openChat(){
    setShouldOpenChat(init=>true);
  }
  function closeChat(){
    setShouldOpenChat(init=>false);
  }
  function onMeetingLeave(){
    setMeetingId(null);
  };

  function GenerateMeetingToken(){
    const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
                axios.get(`${baseUrl}/calls/generate-videosdk-token/`,
                {
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    console.log("token response",response.data?.token);
                    // setAuthToken(init=> response.data?.token)
                    return response
                }).catch(function(error){
                    console.log(error);
                })

        }
  }

// useEffect(function(){
//   GenerateMeetingToken();
  
// },[])

useEffect(function(){
  if (authToken !=null) {
    console.log("97 auth token",authToken);
    const meetingId = (id == null) ?  createMeeting(authToken).then(function(response){
      console.log("meeting id gotten");
      console.log("authToken",authToken);
      return setMeetingId(init=>response);
    }) : setMeetingId(init=>id);
  }
},[authToken]);

useEffect(function(){
  const accessToken = localStorage.getItem('authAccessToken');
  if(accessToken){
    if (meetingId !== null) {
          axios.post(`${baseUrl}/calls/initiate/`,{
            mechanic_id:mechanic_id,
            room_id:meetingId
          },{
            headers:{
              'Authorization':`Bearer ${accessToken}`,
              'Content-Type':'application/json'
            }
          }).then(function(response){
            console.log("initiate call response",response?.data?.id);
              setCall_id(init=>response?.data?.id);
              setCallDuration(init=>response?.data?.expected_call_duration);
              return response
          }).catch(function(error){
            console.log(error);
          })
    } 
  }
},[meetingId])

useEffect(function(){
  const userEmail = localStorage.getItem("userEmail");
  const firstname = localStorage.getItem("firstName")
  const lastname = localStorage.getItem("lastName")
  const ownerToken = localStorage.getItem("deviceToken")
  const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
        axios.get(`${baseUrl}/user/user/${userEmail}/`,{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
        }).then(function(response){
          if(meetingId != null){
            if(userEmail){
              setnotificationPayload(init=>{return {...init,
              owner_image:response?.data?.data?.profile_pic,
              ownerEmail:userEmail,
              email:userEmail,
              ownerId:id,
              ownerName:`${firstname} ${lastname}`,
              meeting_id:meetingId,
              call_token:authToken,
              owner_fcm:ownerToken,
              fcm_token:mech_token,
              is_call:true,
              is_voice:false,
              owner_image:response?.data?.data?.profile_pic,
              initiate_id:Call_id,
            }})
              console.log("notification payload created");
            }
          }
        }).catch(function(error){
          console.log(error);
        })
    }
},[callDuration])

useEffect(function(){
  const accessToken = localStorage.getItem('authAccessToken');

if(notifcationPayload.meeting_id !=""){
  console.log("about to create notification payload");
  // handle notification with backend endpoint
      axios.post(`${baseUrl}/client/send-message/`,{
        ...notifcationPayload
    },{
        headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
        }
    }).catch(function(error){
      console.log("notification endpoint call error",error);
    })
}

},[notifcationPayload])

useEffect(function(){
  setCarOwnerEmail(init=>localStorage.getItem("userEmail"))
},[])

  return <div className="w-screen h-screen relative" >
             {(meetingId !=null ) && (<MeetingProvider config={{ meetingId:meetingId, micEnabled: true, webcamEnabled: true, name: mechanicName,multiStream:false}} token={authToken} reinitialiseMeetingOnConfigChange={true} joinWithoutUserInteraction={true}>
              <Content mechanicName={mechanicName} showRating={()=>{setShowRating(()=>true)}} openChat={openChat} onMeetingLeave={onMeetingLeave} authToken={authToken} mechanicToken={mech_token} room_id={meetingId}/>
              </MeetingProvider>)}
              {shouldOpenChat && <div className="absolute top-0 left-0 z-[10]  ">
                          <SingleChat handleBack={closeChat} carModel={carModel} mechanicName={mechanicName} profile_pic={profile_pic} mechanicEmail={mechanicEmail} carOwnerEmail={CarOwnerEmail}   />
              </div>}
              {showRating && <Rating callId={Call_id} closeFunction={()=>{window.history.back()}} />}
  </div>
} 

function Content({showRating,onMeetingLeave,openChat,authToken,mechanicToken,room_id,mechanicName}){
  const {join, participants,meeting} = useMeeting({
    onParticipantJoined
  });
  const [remoteid,setRemoteId] = useState(null)
  const participantOne = useParticipant([...participants.keys()][0])
  const participantTwo = useParticipant([...participants.keys()][1])
  useEffect(function(){
    
    join()
  },[])

  function onParticipantJoined(participant){
    if(participant.displayName.trim() != String(mechanicName).trim() ){
        setRemoteId(()=>participant.id)
    console.log(" onParticipantJoined", participant.displayName);
    }
  }



  return <div className="w-full h-full">
                    {(remoteid != null) && <RemoteScreen key={remoteid} participantId={remoteid}/>}
                    {/* {thereIsRemote && <RemoteScreen participantId={participants.keys()[0]}/>} */}
                    <div className=" w-full h-full absolute top-0 left-0 flex flex-col justify-between">
                        <LocalScreen participantId={meeting?.localParticipant?.id}/>
                        {/* <LocalScreen participantId={participantOne.isLocal ? [...participants.keys()][0] : [...participants.keys()][1]}/> */}
                        <Controlz showRating = {showRating} openChat={openChat} displayName={mechanicName} authToken={authToken} mechanicToken={mechanicToken} room_id={room_id}/>
                    </div>
  </div>
}

function Countdown({stopCount}){
  const [seconds,setSeconds] = useState(0);
  const [minute,setMinute] = useState(0);
  const [hour,setHour] = useState(0);
const asecond = 1000;
const aMinute = asecond * 60;
const anHour = aMinute *60;
let secondsInterval = useRef('')
let minuteInterval = useRef('')
let hourInterval = useRef('')
function doubleFormat(value){

    if (String(value).length < 2) {
      return `0${value}`
    }else return value
  }

  useEffect(function(){
    secondsInterval.current = setInterval(function(){
        setSeconds(init=>{return init >= 59 ? 0:init +1})
    },1000)

    return function(){
      clearInterval(secondsInterval.current);
      
    }
  },[])

  useEffect(function(){
     minuteInterval.current = setInterval(function(){
        setMinute(init=>{return init >= 59 ? 0:init +1}) 
          return function(){
            clearInterval(minuteInterval.current);
            
          }
    },60000);
  },[])

  useEffect(function(){
     hourInterval.current = setInterval(function(){
      setHour(init=>init++)
    },3600000)

    return function(){
      clearInterval(hourInterval.current);
      
    }
  },[])

  useEffect(function(){
    if (stopCount) {
      clearInterval(secondsInterval.current)
      clearInterval(minuteInterval.current)
      clearInterval(hourInterval.current)
    }
  },[stopCount])

  return <div className="w-fit h-fit p-2 bg-slate-300 bg-opacity-60  rounded-lg">
    <p className="text-white">{doubleFormat(hour)}:{doubleFormat(minute)}:{doubleFormat(seconds)}</p>
  </div>
}

function RemoteScreen({participantId}){
  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);
  // const {disableCam} = useMeeting();
  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current.play().catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);


  // useEffect(function(){
  //   disableCam();
  // },[])

 return <div id="remoteScreen" className="w-full h-full bg-black">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              {webcamOn && <ReactPlayer
                    className="reactVideoPlayer"
                    playsinline 
                    pip={false}
                    light={false}
                    controls={false}
                    muted={false}
                    playing={true}
                    url={videoStream}
                    style={{minHeight:"100%",borderRadius:"0.8rem"}}
                    height={"100%"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />}
         </div>
}

function LocalScreen({participantId}){
  // console.log(participantId);

  const micRef = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } = useParticipant(participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      console.log("web cami s on");
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      console.log("mic ref is not null");
      if (micOn && micStream) {
      console.log("mic  is on and will stream");
        const mediaStream = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);
  

  return  <div id="localScreen" className="relative top-[5vh] right-[-57vw] w-[35vw] h-[25vh] rounded-lg ">
              <audio ref={micRef} autoPlay playsInline muted={isLocal} />
              <ReactPlayer
                    //
                    className="reactVideoPlayer"
                    playsinline // extremely crucial prop
                    pip={false}
                    light={false}
                    controls={false}
                    muted={true}
                    playing={true}
                    //
                    url={videoStream}
                    //
                    style={{minHeight:"25vh",borderRadius:"0.8rem", transform: "scaleX(-1)", WebkitTransform: "scaleX(-1)"}}
                    height={"25vh"}
                    width={"auto"}
                    onError={(err) => {
                      console.log(err, "participant video error");
                    }}
                  />
          </div>
}

function Controlz({displayName,openChat,room_id,authToken,mechanicToken,showRating}){
  const [showCountdown,setShowCountdown] = useState(false)
  const [stopCount,setstopCount] = useState(false)
  const { end, toggleMic, toggleWebcam,} = useMeeting({
    onParticipantJoined,
    onMeetingLeft,
    onParticipantLeft
  });
  const genData = useContext(AuthContext);
  const baseUrl = genData.baseUrl;
  // const isProd = genData.isProd;

  function onParticipantJoined(participant){
    if(participant.displayName.trim() != String(displayName).trim() ){
        setShowCountdown(init=>true)
    }
  }

  function onMeetingLeft(){
    setstopCount(init=>true);
  }

  function onParticipantLeft(){
    handleCallEnd();
  }

  function indicate_call_end_to_server(){
    const accessToken = localStorage.getItem("authAccessToken");
    if(accessToken){
      axios.post(`${baseUrl}/calls/end/`,{
        room_id
        },{
          headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
          }
      }).catch(function(error){
        console.log(error);
      })
    } 
}

function hangupNotifiermech(){
const ownerToken = localStorage.getItem("deviceToken")

  const accessToken = localStorage.getItem('authAccessToken');
    if(accessToken){
      axios.post(`${baseUrl}/client/send-message/`,{
          ownerName:displayName,
          meeting_id:room_id,
          call_token:authToken,
          owner_fcm:ownerToken,
          fcm_token:mechanicToken,
          is_call:false,
          is_voice:false,
          is_ended_call:true
    },{
        headers:{
            'Authorization':`Bearer ${accessToken}`,
            'Content-Type':'application/json'
        }
    }).catch(function(error){
      console.log("notification endpoint call error",error);
    })
  }
}

  function handleCallEnd(){

      indicate_call_end_to_server();
      try {
         end();
      } catch (error) {
        console.log(error);
      }
    hangupNotifiermech();
    showRating()
    // window.location.href = "/mechanic-near-me"
  }

  return <div id="nameAndControls" className="flex flex-col">
              <div className="relative z-[1] px-4 py-1">
                <p className="text-white text-3xl mb-2">{displayName}</p>
                {showCountdown && <Countdown stopCount={stopCount}/>}
              </div>
              <div className="relative flex flex-col text-white items-center">
                <img src={Controll_bg} className="absolute z-[0] bottom-0 left-0 w-full"/>
                <div id="callender" onClick = {handleCallEnd} className=" relative top-[10%] w-fit h-fit p-8 rounded-full bg-red-700">
                      <Call className="z-[1]"/>
                </div>
                <div className="z-[1] relative w-full flex justify-between px-4 py-2 items-baseline">
                  <div onClick={openChat} className="w-fit h-fit bg-white rounded-2xl p-3">
                    <Message variant="Bold" className="text-green-600"/>
                  </div>
                  <p className="text-sm text-slate-200">your call  is secure</p>
                  <div className="w-fit h-fit  bg-slate-200 bg-opacity-30 rounded-2xl p-3">
                    <More className="rotate-90"/>
                  </div>
                </div>
              </div>
</div>
}