import React, { useState, useEffect,useContext } from 'react';
import AuthContext from '../../Context/authContext';
import { Title } from "../../Components/shared/Title";
import styled from "styled-components";
import { useNavigate,useSearchParams } from "react-router-dom";
import { NavBar } from "../../Components/NavBar";
import axios from "axios";
import { RequestLists } from "./components/RequestLists";
import MechAudioCallz from "../MechAudioCall";
import MechCall from "../MechCall";
import BellNotification from "../../Components/notificationBell";
import { Profile } from "iconsax-react";


 

const MechanicDashboard = () => {
  const [firstName, setfirstName] = useState();
  const [lastName, setLastName] = useState();
  const [image, setImage] = useState("");
  const [showVoiceCallSetup, setShowVoiceCallSetup] = useState(false);
  const [ShowVideoCallSetup, setShowVideoCallSetup] = useState(false);
  const [callData,setCallData] = useState(null)
  const [brands, setBrands] = useState([]);
  const [services, setServices] = useState([]);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const genData = useContext(AuthContext);
  const baseUrl = genData.baseUrl;
  // const isProd = genData.isProd;

  function handleIncomingVoiceCall(event){
    console.log("this is the event object received",event);
    setCallData(()=>({...event?.detail?.data}))
    setShowVoiceCallSetup(()=>true);
  }
  function handleIncomingVideoCall(event){
    console.log("this is the event object received",event);
    setCallData(()=>({...event?.detail?.data}))
    setShowVideoCallSetup(()=>true)
  }
  function handleVoiceCallBack(){
    setShowVoiceCallSetup(()=>false);
  }
  function handleVideoCallBack(){
    setShowVideoCallSetup(()=>false)
  }

  useEffect(() => {
    // retieving the first and last name from local storage
    const first_name =
      localStorage.getItem("firstName") || sessionStorage.getItem("firstName");
    const last_name =
      localStorage.getItem("lastName") || sessionStorage.getItem("lastName");

    setfirstName(first_name);
    setLastName(last_name);
  }, []);

  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");
    const userEmail = localStorage.getItem("userEmail");
    axios
      .get(`${baseUrl}/user/user/${userEmail}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setBrands(response.data.data.car_speciality);
        setServices(response.data.data.services);
        setImage((init) => response.data.data.profile_pic);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    document.addEventListener("incoming-video-call",handleIncomingVideoCall)
    return function () {
      document.removeEventListener("incoming-video-call",handleIncomingVideoCall)
    }
  });

  useEffect(() => {
    document.addEventListener("incoming-voice-call",handleIncomingVoiceCall)
    return function () {
      document.removeEventListener("incoming-voice-call",handleIncomingVoiceCall)
    }
  })

 
  return <div>
                <MechanicView>
                  <div className="user-info items-center relative">
                    <div className="info">
                      <div className="img-wrapper">
                      {
                        image !=""
                        ? <img src={image} alt="mechanic" />
                        :<Profile/>
                      }
                      </div>
                      <div className="text">
                        <p className="bold">Welcome back, {firstName}</p>
                        <p className="subtitle">Check out your incoming requests</p>
                      </div>
                    </div>
                    <BellNotification  right={"0rem"}  />
                  </div>
                  <Title text={"Available Requests"} />
                  <Brands brands={brands}/>
                  <Services services={services} />
                  <RequestLists  />
                  {(!ShowVideoCallSetup && !showVoiceCallSetup) && <div className="bottom">
                   <NavBar />
                  </div>}
                </MechanicView>
                {(showVoiceCallSetup && (callData != null)) && (<div className="absolute top-0 left-0 w-screen h-screen bg-white">
                                          <MechAudioCallz call_id={callData.initiate_id} dur={callData.call_duration} callerToken={callData.owner_fcm} customersEmail={callData.ownerEmail} image={callData.owner_image} meetingId={callData.meeting_id} model={`${callData.owner_car_brand} ${callData.owner_car_model}`} name={callData.ownerName} handleBack={handleVoiceCallBack} />
                                        </div>)
                }
                {(ShowVideoCallSetup && (callData != null)) && (<div className="absolute top-0 left-0 w-screen h-screen">
                                          <MechCall callerToken={callData.owner_fcm} carModel={`${callData.owner_car_brand} ${callData.owner_car_model}`} customersEmail={callData.ownerEmail} customersName={callData.ownerName} id={callData.meeting_id} handleBack={handleVideoCallBack} profile_pic={callData.owner_image} />
                                        </div>) }
  </div>
};


function Brands({brands=[]}){
  return  <div className="tabs">
              {brands.map((brand) => {
                return (
                  <div
                    key={brand.id}
                    className="tab"
                  >
                    {brand.name}
                  </div>
                );
              })}
            </div>
}
function Services({services=[]}){
  return  <div className="tabs">
                  {services?.map((service) => {
                    return (
                      <div
                        key={service.id}
                        className="tab"
                      >
                        {service.name}
                      </div>
                    );
                  })}
             </div>
}

export default MechanicDashboard;

const MechanicView = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  position: relative;

  .bottom {
    margin-top: 2rem;
    height: 5rem;
    padding: 20px 0;
    position: fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    z-index: 100;
    background-color: #ffffff;
    border-top: 1px solid #dedede;
  }

  .user-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* gap: 1rem; */

    .info {
      display: flex;
      align-items: center;
      gap: 1rem;

      .img-wrapper {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .bold {
          font-weight: 600;
          font-size: 1rem;
        }

        .subtitle {
          font-size: 0.8rem;
          color: #878c98;
        }
      }
    }
  }

  .tabs {
    width: 98%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 1rem 0;

    .tab {
      font-size: 14px;
      border-radius: 20px;
      padding: 0.3em 1.2rem;
      background-color: #EAFFF8;
    }
  }
`;
