import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import Dashboard from './Components/Dashboard';
import Transactions from './Components/Transactions';
import CallLogs from './Components/CallLogs';
import CarOwners from './Components/CarOwners';
import Mechanics from './Components/Mechanics';
import MechanicProfile from './Components/MechanicProfile';
import Login from './Components/Login';
import ErrorPage from './Components/404';
import RequestMap from './Components/map';
import './App.css';
import Faq from './Components/Faq';
import { scrollToElement } from './Components/utils';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce, Slide, ToastContainer, toast } from 'react-toastify';
import OnboardClient from './pages/onboardClient';
import LoginII from './pages/login';
import MechanicCreateAccount1 from './pages/Mechanic-Onboarding/mechanicCreateAccount1';
import CarOwnerCreateAccount1 from './pages/CarOwner-Onboarding/carOwnerCreateAccount1';
import ProfileType from './pages/profileType';
import CarOwnerDashboard from './pages/carOwnerDashboard';
import CarOwnerCreateAccount2 from './pages/CarOwner-Onboarding/carOwnerCreateAccount2';
import MechanicDashboard from './pages/mechanic/mechanicDashboard';
import MechanicCreateAccount2 from './pages/Mechanic-Onboarding/mechanicCreateAccount2';
import MechanicCreateAccount3 from './pages/Mechanic-Onboarding/mechanicCreateAccount3';
import CarOwnerCreateAccount3 from './pages/CarOwner-Onboarding/carOwnerCreateAccount3';
import SuccessVerification from './pages/successVerification';
import ProfilePage from './Components/clientProfilePage';
import PersonalInfo from './Components/clientPersonalInfo';
import MechProfile from './Components/profileMechanic';
import MechanicAvailability from './Components/mechanicAvailability';
import GetHelp from './Components/getHelp';
import Legal from './Components/legal';
import ClientWallet from './pages/clientWallet';
import MechanicWallet from './pages/mechanicWallet';
import Topup from './pages/topUp';
import Withdraw from './pages/withdraw';
import { ScheduledCalls } from './pages/mechanic/components/ScheduledCalls';
import ResetPassword from './pages/resetPassword';
import UserOTP from './pages/userOTP';
import VideoCall from './pages/videoCall';
import Chat from './pages/chat';
import SingleChat from './pages/chat/components/SingleChat';
import MechCall from './pages/MechCall';
import AudioCallz from './pages/AudioCall';
import MechAudioCallz from './pages/MechAudioCall';
import CarOwnerProfile from './Components/CarOwnerProfile';
import { Request } from './pages/mechanic/components/Request';
import Requests from './Components/requests';
import RegistrationVerification from './pages/RegVerification';
import NotificationsPage from './pages/Notifications';
import DetailingPage from './pages/Detailing';
import Services from './pages/Detailing/pages/services';
import BookNow from  './pages/Detailing/pages/book';
import Thanks from './pages/Detailing/pages/thanks';

const userEmail = localStorage.getItem('userEmail');

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState(''); //saving password to state to be used for automatic login at the otp page
  

  useEffect(() => {
    const hash = window.location.hash;
    if (hash === "#FAQs") {
      scrollToElement("#FAQs"); // Scroll to FAQ section when URL contains #FAQs
    }
  }, []); // Run only once on component mount

  useEffect(() => {
    console.log("notification effect");
    if ('Notification' in window) {
      if (Notification.permission === "granted") {
        console.log('Notification Works on this Device');
      } else if (Notification.permission === "denied") {
        Notification.requestPermission().then(function(result) {
          if (result === "granted") {
            console.log('Notification granted on the Device');
          } else {
            alert("NOTE!!! Chat and calls functionality on requestmechanic might be severely affected");
          }
        });
      }
    } else {
      console.error("This browser does not support notifications.");
    }
  }, []);

  return (
    <div className="">
      {/* here */}
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/mechanic-near-me" element={<PrivateRoute element={<RequestMap />} />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/call" element={<VideoCall />} />
          <Route path="/personal-info" element={<PersonalInfo />} />
          <Route path="/mechanic-profile" element={<MechProfile />} />
          <Route path="/mechanic-availability" element={<MechanicAvailability />} />
          <Route path="/user-help" element={<GetHelp />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/wallet" element={<ClientWallet />} />
          <Route path="/mechanic-wallet" element={<MechanicWallet />} />
          <Route path="/topup" element={<PrivateRoute element={<Topup />} />} />
          <Route path="/withdraw" element={<PrivateRoute element={<Withdraw />} />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/chat-room" element={<SingleChat />} />
          <Route path="/client-call" element={<MechCall />} />
          <Route path="/audio-call" element={<AudioCallz />} />
          <Route path="/mech-audio-call" element={<MechAudioCallz />} />
          <Route path="/verify" element={<RegistrationVerification />} />
          <Route path="/notifications" element={<NotificationsPage />} />
          <Route path="/detailing" element={<DetailingPage />} />
          <Route path="/detailing/services" element={<Services />} />
          <Route path="/detailing/book" element={<BookNow />} />
          <Route path="/detailing/thanks" element={<Thanks />} />
          <Route
            path="/dashboard/*"
            element={<PrivateRoute element={<Dashboard userEmail={userEmail} />} />}
          />
          <Route
            path="/transactions/*"
            element={<PrivateRoute element={<Transactions userEmail={userEmail} />} />}
          />
          <Route
            path="/reset-password/*"
            element={<PrivateRoute element={<ResetPassword />} />}
          />
          <Route
            path="/call-logs"
            element={<PrivateRoute element={<CallLogs userEmail={userEmail} />} />}
          />
          <Route
            path="/requests"
            element={<PrivateRoute element={<Requests userEmail={userEmail} />} />}
          />
          <Route
            path="/car-owners"
            element={<PrivateRoute element={<CarOwners userEmail={userEmail} />} />}
          />
          <Route
            path="/car-owner/:email"
            element={<PrivateRoute element={<CarOwnerProfile userEmail={userEmail} />} />}
          />
          <Route
            path="/mechanics"
            element={<PrivateRoute element={<Mechanics userEmail={userEmail} />} />}
          />
          
          <Route
            path="/mechanics/:email"
            element={<PrivateRoute element={<MechanicProfile userEmail={userEmail} />} />}
          />

          {/* temporary or placeholder routes */}
          <Route
            path="/onboarding"
            element={<OnboardClient />}
          />
          <Route
            path="/create-account"
            element={<ProfileType />}
          />
          <Route
            path="/login-user"
            element={<LoginII password={password} setPassword={setPassword} />}
          />
          <Route
            path="/carowner-create-account1"
            element={ 
              <CarOwnerCreateAccount1 password={password} setPassword={setPassword} />
          }
          />
          <Route
            path="/carowner-create-account2"
            element={<CarOwnerCreateAccount2 />}
          />
          <Route
            path="/carowner-create-account3"
            element={<CarOwnerCreateAccount3 />}
          />
          <Route
            path="/mechanic-create-account1"
            element={<MechanicCreateAccount1 password={password} setPassword={setPassword} />}
          />
          <Route
            path="/mechanic-create-account2"
            element={<MechanicCreateAccount2 />}
          />
          <Route
            path="/mechanic-create-account3"
            element={<MechanicCreateAccount3 />}
          />

          <Route
            path="/user-otp"
            element={<UserOTP password={password} setPassword={setPassword} />}
          />
          <Route
            path="/success-verification"
            element={<SuccessVerification />}
          />
          <Route
            path="/mechanic-dashboard"
            element={<MechanicDashboard />}
          />
          <Route
            path="/mechanic/scheduled-calls"
            element={<ScheduledCalls />}
          />
          <Route
            path="/reset-password"
            element={<ResetPassword />}
          />

          {/* ////// */}

          <Route
            path="*"
            element={<ErrorPage />}
          />
          <Route path="/faq" element={<Faq />} />
        </Routes>
      </Router>

      {/* for pop up notifications */}
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        limit={1}
        transition={Slide}
        theme="light"
        bodyClassName="w-[100px]"
      />
    {/* here */}
    </div>
  );
}

const PrivateRoute = ({ element, path }) => {
  const [authenticated, setAuthenticated] = useState(!!localStorage.getItem('authAccessToken'));

  useEffect(() => {
    const authState = localStorage.getItem('authAccessToken');
    if (authState !== null) {
      setAuthenticated(true);
    }
  }, []);

  return authenticated ? (
    element
  ) : (
    <Navigate to="/login-user" replace />
  );
};

export default App;
