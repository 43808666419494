import React, { useState, useEffect,useContext,useRef } from 'react';
import AuthContext from "../Context/authContext";
import { User,ArrowRight2,Edit, ProfileDelete} from "iconsax-react";
import small from "../Images/profile image backdrop/small.png"
import medium from "../Images/profile image backdrop/medium.png"
import large from "../Images/profile image backdrop/large.png"
import xlarge from "../Images/profile image backdrop/xlarge.png"
import placeholder from "../Images/1 1.png"
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import imageCompression from "browser-image-compression";
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteModal } from "./DeleteModal";


 
export default function ProfilePage(){
    const [profilePic, setProfilePic] = useState(null);
    const [profileName, setProfileName] = useState("");
    const [showDelete, setShowDelete] = useState(false);
    const fileInputRef = useRef(null);
    const imageRef = useRef(null);
    const [uploading, setUploading] = useState(false)
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;

    useEffect(function(){
        if(imageRef.current != null){
            console.log("width:",imageRef.clientWidth , imageRef.clientHeight);
    }
        const accessToken = localStorage.getItem('authAccessToken');
        const userEmail = localStorage.getItem('userEmail');
        if (accessToken) {
            try{
                axios.get(`${baseUrl}/user/user/${userEmail}/`,{
                    headers:{
                        'Authorization': `Bearer ${accessToken}`,
                    }
                }).then(function(response){
                    setProfileName(init=>`${response.data.data.first_name} ${response.data.data.last_name}`)
                    setProfilePic(init=>response.data.data.profile_pic)
                    console.log("profile page query response",response);
                }).catch(function(error){
                    console.log(error)
                })
            }catch{
                console.log("an error occured while fetching profile data")
            }
        }
    },[])
    
    function changeProfilePic(){
    if (fileInputRef != null) {
        fileInputRef.current?.click()
    }

    }

    function handleFilechange(file){
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        }
        setProfilePic(init=> URL.createObjectURL(file));
        const accessToken = localStorage.getItem('authAccessToken');
        if (accessToken) {
            try{
                setUploading(init => true)
                imageCompression(file, options).then(function(compressedFile){
                    console.log(`compressed image has a size of ${compressedFile.size / 1024 / 1024} MB`);
                    axios.put(`${baseUrl}/user/profile/update-profile-picture/`,
                    {
                        profile_pic: new File([compressedFile],compressedFile.name,{
                            type:compressedFile.type
                          })
                    },{
                        headers:{
                            'Authorization': `Bearer ${accessToken}`,
                            'Content-Type': 'multipart/form-data',
                        }
                    }).then(function(response){
                        console.log(response);
                        console.log("image uploaded");
                        setUploading(init => false);
                        return response
                    }).catch(function(error){
                        console.log(error);
                        setUploading(init => false);
                    })
                })
            }catch{
                console.log("an error occured while fetching profile data")
            }
        }
    }

    return <div className="w-screen">
                <div className="flex flex-col justify-center items-center">
                    <picture>
                        <source srcSet={small} media="(min-width: 1240px)" />
                        <source srcSet={medium}  media="(min-width: 960px)" />
                        <source srcSet={large} media="(min-width: 726px)"/>
                        <source srcSet={xlarge} media="(min-width: 456px)" />
                        <img  src={medium} className="w-full h-[15vh]"/>
                    </picture>
                    {profilePic ? <div  className=" box-border flex justify-center items-center  h-[35vw]  w-[35vw] overflow-hidden rounded-full relative bottom-[3.5rem]">
                        <img ref={imageRef} src={profilePic} alt="profile pic"  /></div> : 
                        <div className="bg-slate-400 rounded-full p-6 relative bottom-[2.5rem]"><User variant="TwoTone" className="w-[4rem] h-[4rem]" /></div>}
                    <input onChange={(e)=>handleFilechange(e.target?.files[0])} type="file" ref={fileInputRef}  className="hidden" />
                    <div className=" relative bottom-[5rem] left-[2.5rem] w-fit h-fit p-2 rounded-full bg-green-300"><Edit onClick={changeProfilePic}  className=" w-[2rem] h-[2rem] "/></div>
                    <p className="relative bottom-[3rem] font-bold text-2xl flex gap-2 items-center">{profileName}</p>
                </div>
                <div className="flex flex-col px-4 mt-50 gap-2">
                    <p className="font-bold mb-3">My Account</p>
                    <Link to={"/personal-info"}><Card sx={{padding:"1rem"}}>
                        <div className="flex justify-between" ><p className="flex gap-2 justify-between"><User/> Personal Information</p> <ArrowRight2/></div>
                    </Card></Link>
                    <div onClick={() => setShowDelete(!showDelete)}>
                        <DeleteAccount title={"Delete Account"} Icon1={ProfileDelete} />
                    </div>
                </div>
                <DeleteModal showModal={showDelete} setShowModal={setShowDelete}/>
                {uploading && <BlurLoader/>}
            </div>
}


function BlurLoader(){
    return <div style={{backgroundColor:"rgba(0,0,0,0.8)"}} className="absolute w-screen h-screen top-0 left-0  flex flex-col justify-center items-center">
              <CircularProgress variant="indeterminate" color="success" />
              <p className="text-white">Image Uploading please Wait</p>
    </div>
  }

function DeleteAccount({ title, Icon1 }) {
    return (
      <Card sx={{ padding: "1.5rem 1rem" }}>
        <div className="flex justify-between cursor-pointer">
          <p className="flex gap-2 justify-between">
            {Icon1 && <Icon1 />}
            {title}
          </p>
          <ArrowRight2 />
        </div>
      </Card>
    );
  }