import { Map,APIProvider, Pin,AdvancedMarker } from "@vis.gl/react-google-maps";
import React, { useState, useEffect,useContext } from 'react';
import AuthContext from "../../../Context/authContext";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { PaystackButton } from "react-paystack";
import { ArrowLeft2 } from "iconsax-react";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import {Header,Footer} from "../components"
import serviceImage from "../../../Images/temporary/ext-wash-p-1080.jpg";
import detailImage from "../../../Images/temporary/detail.png";

    const serviceLists = [
        {image:serviceImage,title:"Full Detail"},
        {image:serviceImage,title:"Interior Only"},
        {image:serviceImage,title:"Exterior Only"},
        {image:serviceImage,title:"Ceramic Coating"},
        // {image:serviceImage,title:"Protection Film"},
    ]

    // service plan amount transaction id address name email wateraccess carmodel  caryear time and date of service

    const FullDetailLists = [
        // {image:serviceImage,amount:"599,995",price:"599995",perks:[],duration:"",title:"Master Piece"},
        // {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Gold Standard"},
        // {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Full Refresh"},
        {image:serviceImage,amount:"199,995",price:"199995",perks:["Shampoo Seats & Carpet","Double Vacuum Interior","Wipe all Surfaces","Stain (Spot Treatment)","Clean & Protect Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk","Exterior Wash", "tire cleaning" ," basic interior vacuum"],duration:"5 hours",title:"Sedan"},
        {image:serviceImage,amount:"239,995",price:"239995",perks:["Shampoo Seats & Carpet","Double Vacuum Interior","Wipe all Surfaces","Stain (Spot Treatment)","Clean & Protect Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk","Exterior Wash", "tire cleaning" ," basic interior vacuum"],duration:"8 Hours",title:"SUV"},
        {image:serviceImage,amount:"139,995",price:"139995",perks:["Double Vacuum Interior","Wipe all surfaces","Stains (Spot Treatment)","Windows & Mirrors","Clean & Protect Plastic","Detail Floor Mats and Shine","Detail Trunk & Door Jambs","Spot Polish","Professional Hand Wash","Clay Bar Exterior","Detail Rims & Tires","Wheel Wells","Wax Protection (3 Months)",],duration:"3 Hours",title:"Sedan & SUV"},
        {image:serviceImage,amount:"189,995",price:"189995",perks:["Shampoo Seats & Carpet","Double Vacuum Interior","Wipe all Surfaces","Stain (Spot Treatment)","Clean & Protect Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk","Exterior Wash", "tire cleaning" ," basic interior vacuum"],duration:"? Hours",title:"Dealership & Rentals (min 10 vehicles)"},
    ]
    const InteriorDetailLists = [
        {image:serviceImage,amount:"139,995",price:"139995",perks:["Deep Cleaning Treatment","Shampoo All Carpets & Seats","Double Vacuum Interior","Stain Extraction","Clean & Protect All Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],duration:"4 hours",title:"Deep Shampoo (Sedan)"},
        {image:serviceImage,amount:"189,995",price:"189995",perks:["Deep Cleaning Treatment","Shampoo All Carpets & Seats","Double Vacuum Interior","Stain Extraction","Clean & Protect All Plastic","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],duration:"5 hours",title:"Deep Shampoo (SUV)"},
        {image:serviceImage,amount:"93,995",price:"93995",perks:["Complete Interior Vacuum","Wipe All Surface","Stains (Spot Treatment)","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],duration:"3 Hours",title:"Classic Interior (Sedan)"},
        {image:serviceImage,amount:"143,995",price:"143995",perks:["Complete Interior Vacuum","Wipe All Surface","Stains (Spot Treatment)","Windows & Mirrors","Detail Floor Mats and Shine","Detail Trunk",],duration:"4 Hours",title:"Classic Interior (SUV)"},
        {image:serviceImage,amount:"193,995",price:"193995",perks:[],duration:"4 hours",title:"Hypo Detail (Sedan)"},
        {image:serviceImage,amount:"214995",price:"214995",perks:[],duration:"5 hours",title:"Hypo Detail (SUV)"},
    ]
    const ExteriorDetailLists = [
        // {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Cut and polish"},
        // {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Wax and Buff"},
        {image:serviceImage,amount:"193,995",price:"193995",perks:["2-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],duration:"5 hours",title:"Sedan"},
        {image:serviceImage,amount:"243,995",price:"243995",perks:["2-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],duration:"7 Hours",title:"SUV"},
        {image:serviceImage,amount:"159,995",price:"159995",perks:["1-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],duration:"4 Hours",title:"Wax & Buff"},
        {image:serviceImage,amount:"209,990",price:"209990",perks:["1-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],duration:"4 Hours",title:"Wax & Buff (Sedan & SUV)"},
        {image:serviceImage,amount:"89,995",price:"89995",perks:["Basic Spot Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail & Mirrors","Wax Protection (3 Months)",],duration:"3 Hours",title:"Classic Exterior (Sedan & SUV)"},
        {image:serviceImage,amount:"215,990",price:"215990",perks:["2-Step Paint Correction Polish","Professional Hand Wash","Clay Bar Exterior","Wheel Wells","Detail Rim & Tires","Window & Mirrors","Wax Protection (3 Months)",],duration:"? Hours",title:"Dealership & Rentals (min 10 vehicles)"},
    ]
    const CoatingDetailLists = [
        // {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"5 year Shield"},
        {image:serviceImage,amount:"599,995",price:"599995",perks:[],duration:"8 Hours",title:"10H Ceramic Coating 4 Year Shield (Sedan)"},
        {image:serviceImage,amount:"799,995",price:"799995",perks:[],duration:"8 Hours",title:"10H Ceramic Coating 4 Year Shield (SUV)"},
        {image:serviceImage,amount:"199,995",price:"199995",perks:[],duration:"1 hour",title:"Ceramic Engine Bay"},
        {image:serviceImage,amount:"99,995",price:"99995",perks:[],duration:"1 hour",title:"Ceramic Door Jambs"},
    ]
    // const ProtectionDetailLists = [
    //     {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Bummper"},
    //     {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Partial PPF"},
    //     {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Full Front PPF"},
    //     {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"Complete PPF"},
    //     {image:serviceImage,amount:"",price:"200000",perks:[],duration:"",title:"ExoShield"},
    // ]
    

export default function BookNow(){
    const screenId = {none:"none",service:"service"}
    const mapkey = "AIzaSyCGvCxKjSzfDtVS6fxJTUEeUXDI_UaDxGM";
    const mapid = "de861107584712d2";
    const [pinPositionlat,setPinPositionlat] = useState( 6.6413477);
    const [pinPositionlng,setPinPositionlng] = useState(3.3358670);
    const [selectedLocation,setSelectedLocation] = useState(null);
    const [screen,showScreen] = useState(screenId.none);
    const [generatedData,setGeneratedData] = useState({});


    function handleNextClick(){
        showScreen(()=>screenId.service);
    }
    function handleServiceBack(){
        showScreen(()=>screenId.none)
    }
    function handlePlanBack(){
        setGeneratedData(init=>({...init,service:""}))
    }
    function getSelectedLocation(value){
        setSelectedLocation(()=>value)
    }
    function closeBottomModal(){
        setGeneratedData(init=>({...init,plan:""}))
    }
    function handleAddPackage(price){
        setGeneratedData(init=>({...init,price:price}))
    }
    function handleAddtime(time){
        setGeneratedData(init=>({...init,time:time}))
    }
    function handleWaterNmodel(data){
        setGeneratedData(init=>({...init,waterNmodel:data}))
    }


    useEffect(function(){
        console.log(generatedData);
    },[generatedData])

    return <div className="w-full ">
                <div className="w-full h-[100vh]  relative flex flex-col items-center justify-center ">
                    <div className="absolute top-0 left-0 h-fit w-full   " >
                        <Header/>
                    </div>
                    <APIProvider apiKey={mapkey} >
                            <Map
                            style={{width:"100vw",height:"100vh"}}
                            defaultCenter={{lat: pinPositionlat , lng: pinPositionlng}}
                            defaultZoom={15}
                            gestureHandling={'greedy'}
                            disableDefaultUI={true}
                            mapId={mapid}
                            center={{lat:pinPositionlat,lng: pinPositionlng}}
                            >
                                <AdvancedMarker draggable position = {{lat:pinPositionlat,lng: pinPositionlng}}>
                                    <Pin  background={"green"} borderColor={"black"} glyphColor={"black"}/>
                                </AdvancedMarker>
                            </Map>
                    </APIProvider> 
                    <SearchBar locationSetter={getSelectedLocation} />
                    {(selectedLocation) && <button onClick={handleNextClick} className="absolute top-[90vh] shadow-lg bg-green-500 text-white py-3 w-[90%] rounded-lg ">Next</button>}
                    {screen == screenId.service && <AScreen handleBack={handleServiceBack} data={serviceLists} valueSetter = {function(value){setGeneratedData(init=>({...init,service:value}))}} />}
                    {generatedData.service == serviceLists[0].title && <AScreen handleBack={handlePlanBack} data={FullDetailLists} valueSetter = {function(value){setGeneratedData(init=>({...init,plan:value}))}} />}
                    {generatedData.service == serviceLists[1].title && <AScreen handleBack={handlePlanBack} data={InteriorDetailLists} valueSetter = {function(value){setGeneratedData(init=>({...init,plan:value}))}} />}
                    {generatedData.service == serviceLists[2].title && <AScreen handleBack={handlePlanBack} data={ExteriorDetailLists} valueSetter = {function(value){setGeneratedData(init=>({...init,plan:value}))}} />}
                    {generatedData.service == serviceLists[3].title && <AScreen handleBack={handlePlanBack} data={CoatingDetailLists} valueSetter = {function(value){setGeneratedData(init=>({...init,plan:value}))}} />}
                    {/* {generatedData.service == serviceLists[4].title && <AScreen handleBack={handlePlanBack} data={ProtectionDetailLists} valueSetter = {function(value){setGeneratedData(init=>({...init,plan:value}))}} />} */}
                    {(generatedData.plan && generatedData.plan !="") && <PriceScreen handleAdd={handleAddPackage} handleClose={closeBottomModal}  duration={generatedData?.plan?.duration} price={generatedData?.plan?.price} Perks ={generatedData?.plan?.perks} Image={detailImage} amount={generatedData?.plan?.price} plan={generatedData?.service} />}
                    {generatedData.price && <DateScreen handleNextClick={handleAddtime} /> }
                    {generatedData.time && <WaterANModel handleNextClick={handleWaterNmodel} />}
                    {generatedData.waterNmodel && <EmailNName generatedData={generatedData} amount={generatedData?.plan?.cost} />}
                </div>
                <Footer/>
    </div>
}

function AddressResults({results = [],inputSetter,resultCloser}){
    function handleAresultClick(value){
    console.log("handleAresultClick",value);
        inputSetter(value);
        // resultCloser(init=>false);
    }
    return <div className="max-w-[95%] self-center max-h-[30vh] overflow-y-scroll" >
                {
                    results.map(function(aRes){
                        return <Aresult data={aRes} handleClick={handleAresultClick}/>
                    })
                }
                {results.length === 0 && <p>no results found</p>}
            </div>
}

function Aresult({data,handleClick}){
    return <div onClick={()=>handleClick(data)} className="bg-lime-200 w-full px-3 py-2 rounded-md border-[0.02rem] border-black border-solid">
        <p className="w-full overflow-hidden text-ellipsis ">{data.formatted_address}</p>
    </div>
}

function SearchBar({issuesData,location,locationSetter}){
    const initialData = issuesData
    const [inputValue,setInputValue] = useState(location||'') ;
    const [showResultComp,setShowResultComp] = useState(false);
    const [addressResults,setAddressResults] = useState([]);
    const[payload,setPayload] = useState(initialData);
    const[addressChosen,setAddressChosen] = useState(false);
    const[CountryOfResidence,setCountryOfResidence] = useState(true);
    const mapKey = "AIzaSyCGvCxKjSzfDtVS6fxJTUEeUXDI_UaDxGM";
    const [searchParams,setSearchParams] = useSearchParams()

    useEffect(function(){
        if (searchParams.get("address")) {
            let adress = searchParams.get("address").replace("+"," ","g")
            setInputValue(init=>adress);
            setAddressChosen(init=>true);
            setCountryOfResidence(init=>true)
            locationSetter(adress)
        }
    },[])
    
    function storeToUrl(key,value){
        return new Promise(function(resolve,reject){
            searchParams.append(key,value);
            setSearchParams(searchParams);
            console.log(key,value);
            return resolve();
        })
    }

    function removeUrlStore(key){
        return new Promise(function(resolve,reject){
            searchParams.delete(key)
            setSearchParams(searchParams)
            return resolve();
        })
    }

    function handleAdressResultOptionClick(value){
        const isNigeria = value.address_components.find(function(addressComponent){
            return addressComponent.types?.includes("country") && addressComponent.long_name === "Nigeria"
        })
        console.log("handleAdressResultOptionClick",value);
        locationSetter(value.formatted_address)
        storeToUrl("address",value.formatted_address)
        .then(function(response){
            setInputValue(init=>value.formatted_address);
            setPayload(function(init){ console.log("updating payload with :",value); return {...init,address:value.formatted_address}})
            console.log(payload);
        })
    }
    
    useEffect(function(){
    
        const timeout = setTimeout(() => {
            handleFetch()
        }, 1000);
    
        return ()=> clearTimeout(timeout)
    },[inputValue])
    
    function handleInputChange(value){
        setInputValue(init=> value);

    }

    function handleFetch(){
        if (inputValue !== "" ) {        
            fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${inputValue}&key=${mapKey}`)
            .then(function(raw){
                return raw.json()
            }).then(function(response){
                console.log(response);
                setAddressResults(init=> {
                    if (response.status ==="OK") {
                        return response.results
                    }
                })
            }).catch((error)=>console.log(error))
        }
    }
    function handleSearchFocus(){
        setShowResultComp(init=>true);
    }
        return <div className="absolute top-[15vh] w-[90%] flex flex-col gap-2">
                    <input onFocus={handleSearchFocus} placeholder="Enter Your Address" onChange={(e)=>handleInputChange(e.target.value)} className="  bg-white self-center border-slate-500 border-solid border-[0.02rem] rounded-md py-3 px-3 w-[95%]" value={inputValue} />
                    {showResultComp && <AddressResults inputSetter={handleAdressResultOptionClick} locationSetter={locationSetter} resultCloser={setShowResultComp} results={addressResults || []}/>}
        </div>
}

function Services({valueSetter}){

    function handleClick(serviceType){
        valueSetter(init=>({...init,service:serviceType}))
    }
    const serviceOptions = [
        {image:serviceImage},
        {image:serviceImage},
        {image:serviceImage},
        {image:serviceImage},
        {image:serviceImage},
    ]
    return <div className="fixed z-[60] top-0 left-0 bg-white w-screen h-full p-4 ">
                <div className="flex justify-between items-center">
                    <ArrowLeft2 className="text-green-600"/> <p className="text-xl font-semibold">Select a Service</p> <div></div>
                </div>
                <div className="grid grid-cols-2 gap-4 pt-8">
                    {
                        serviceOptions.map(function(anOption,index){
                            return <Aservice image={anOption.image} title={serviceLists[index]} handleClick={handleClick} />
                        })
                    }
                    
                </div>
            </div>
}

function Aservice({image,title,handleClick,price,cost,perks,duration}){

    function rehandleClick(){
        if(price){
            handleClick({title:title,cost:cost,price,perks,duration})
        }else{ 
            handleClick(title)
        }
    }

    return <div onClick={rehandleClick} className=" border-[0.1rem] border-solid border-slate-200 rounded-xl p-3 gap-2 flex flex-col justify-center items-center">
                <div className="w-full">
                    <img src={image} className=" rounded-md" />
                </div>
                <p className="font-medium text-center">{title}</p>
                <button className="text-slate-700">{price? `₦ ${price}` : "View Options"}</button>
    </div>
}

function AScreen({valueSetter,data,handleBack}){
    function handleClick(serviceType){
        valueSetter(serviceType)
    }


    return <div className="fixed z-[60] top-0 left-0 bg-white w-screen h-full p-4 ">
                <div className="flex justify-between items-center">
                    <ArrowLeft2 onClick={handleBack} className="text-green-600"/> <p className="text-xl font-semibold">Select a Service</p> <div></div>
                </div>
                <div className="grid grid-cols-2 gap-4 pt-8">
                    {
                        data.map(function(anOption){
                            return <Aservice image={anOption.image} duration={anOption.duration} perks={anOption.perks} price={anOption?.amount} cost={anOption?.price} title={anOption.title} handleClick={handleClick} />
                        })
                    }
                    
                </div>
            </div>
}

function PriceScreen({amount,plan,Image,Perks=[],price,duration,handleAdd,handleClose}){
    return <div className="fixed  z-[60] bottom-0 left-0 bg-white w-screen  p-1 flex flex-col items-center rounded-t-xl">
                <div className="w-full flex p-4 justify-between items-center">
                    <ArrowLeft2 onClick={handleClose} className="text-green-500" />
                    <p>{plan}</p>
                    <p onClick={handleClose} className="text-green-500">close</p>
                </div>
                <div className="w-[50%]">
                    <img src={Image} />
                </div>
                <div className="pb-[5rem]">
                    {/* <p className="py-[1rem]">Our Ultimate Ceramic Coating Package</p> */}
                    <p className="pb-[0.5rem] font-medium">What's Included?</p>
                    <div className=" ">
                        {
                            Perks.map(function(aPerk){
                                return <p className="pb-[0.2rem]">. {aPerk}</p>
                            })
                        }
                    </div>
                    <div className="flex py-[1rem] gap-2">
                        <p>{price}.</p>
                        <p className="text-slate-700">{duration}</p>
                    </div>
                </div>

                <button onClick={()=>handleAdd(amount)} className="absolute bottom-8 text-white bg-green-600 rounded-xl w-[90%] py-3">Add 1. {price}</button>
            </div>
}

function DateScreen({handleNextClick}){
    const [dateTimeValue,setDateTimeValue] = useState(null);

    function handleDateTimeChange(value){
        console.log(value);
        setDateTimeValue(()=>value)
    }

    return <div  className="fixed z-[60] top-0 left-0 bg-white w-screen h-full p-4 ">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDateTimePicker onChange={(e)=>handleDateTimeChange(e.format("DD/MM/YY-h:mm:a"))} minDate={dayjs()} defaultValue={dayjs()} />
                    <button onClick={()=>handleNextClick(dateTimeValue)} className="absolute bottom-8 text-white bg-green-600 rounded-xl w-[90%] py-3">Next</button>
                </LocalizationProvider>
    </div>
}

function WaterANModel({handleNextClick}){
    const [data,setData] = useState({});

    function handleNext(){
        if (data.water && data.carModel && data.carYear) {
            handleNextClick(data)
        }
    }

    function handleDataChange(value,property){
        setData(init=>({...init,[property]:value}))
    }
    return <div className="fixed z-[60] top-0 left-0 bg-white w-screen h-full p-4 flex flex-col gap-10 " >
                <p className="mt-16 font-sharp font-semibold text-2xl">Do You Have Access To Water And Electricty Within 100 Feet of the Vehicle?</p>
                <select onChange={(e)=>handleDataChange(e.target.value,"water")} className="border-solid border-slate-200 border-[0.1rem] rounded-xl p-4">
                    <option value={"yes"}> Yes</option>
                    <option value={"No"}> No - This service is Not Available</option>
                </select>
                <p className="font-sharp font-semibold text-2xl">Vehicle Model And Year</p>
                <input onChange={(e)=>handleDataChange(e.target.value,"carModel")} placeholder="Car Model" className="border-solid border-slate-200 border-[0.1rem] rounded-xl p-4"/>
                <input onChange={(e)=>handleDataChange(e.target.value,"carYear")} placeholder="Car Year" className="border-solid border-slate-200 border-[0.1rem] rounded-xl p-4"/>
                <button onClick={handleNext} className="absolute bottom-8 text-white bg-green-600 rounded-xl w-[90%] py-3">Next</button>

    </div>
}

function EmailNName({amount,generatedData}){
    const [data,setData] = useState({});
    const testKey = "pk_test_fe5246f84602ecaa3e268e7fc9697becdd858bea"
    const liveKey = "pk_live_e1cf359d2c1a28bda316bcb2a6385db7a3ce2f9f"
    const genData = useContext(AuthContext);
    const isProd = genData.isProd;
    const ants = {default:'default',error:'error',success:'success',loading:'loading',formError:"field-incompletely-filled"};
    const [requestState,setRequestState] = useState(ants.default);

    
    function SubmitForm(){

            setRequestState(()=>ants.loading);
            axios.post("https://formsubmit.co/ajax/okiemuteonojakpor@gmail.com",
            {
                ...generatedData
            },
            {
                headers:{
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
            ).then(function(response){
                setRequestState(()=>ants.success);
                return response
            })
            .catch(function( error){
                setRequestState(()=>ants.error);
                return error
            })
            .finally(()=>{
                setTimeout(() => {
                    setRequestState(()=>ants.default);
                }, 2000);
                // clearFields()
                window.location.href = "/detailing"
            });
    }
    

    function handleDataChange(value,property){
        setData(init=>({...init,[property]:value}))
    }

    function handleSuccess(){
        SubmitForm()
    }
    
    return <div className="fixed z-[60] top-0 left-0 bg-white w-screen h-full p-4 flex flex-col gap-40 " >
                <p className="font-sharp text-2xl font-bold ">Your Details</p>
                <div className="flex flex-col gap-3">
                    <input onChange={(e)=>handleDataChange(e.target.value,"name")} className="p-3 border-solid border-slate-200 rounded-lg border-[0.1rem]" placeholder="Your Full Name"/>
                    <input onChange={(e)=>handleDataChange(e.target.value,"email")} type="email" className="p-3 border-solid border-slate-200 rounded-lg border-[0.1rem]" placeholder="Your Email"/>
                    <input onChange={(e)=>handleDataChange(e.target.value,"phoneNumber")} className="p-3 border-solid border-slate-200 rounded-lg border-[0.1rem]" placeholder="Your Phone Number"/>
                </div>
                <PaystackButton onSuccess={(e)=>handleSuccess()}  amount={Number(amount) * 100} email={data.email} publicKey={isProd?liveKey:testKey}className="bg-green-500 text-white py-2 w-full rounded-md">Submit Booking</PaystackButton>
                

    </div>
}
