import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import TopHeader from "./TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { FiMenu, FiX } from "react-icons/fi";
import axios from "axios";
import { Head } from "./transactionHistory";
import { CallCalling } from "iconsax-react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import TableRowsSelect from "./TableSelectRows";

const isProd = window.location.hostname.includes("requestmechanic.com");

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

const CallLogs = ({ userEmail }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [callLogs, setCallLogs] = useState([]);
  const [totalCallsData, setTotalCallsData] = useState({
    answered_calls: 0,
    declined_calls: 0,
    ended_calls: 0,
    pending_calls: 0,
    total_calls: 0,
    total_calls_month: 0,
    total_calls_today: 0,
    total_calls_year: 0,
  });
  // const [searchParams, setSearchParams] = useSearchParams();
  // const limit = parseInt(searchParams.get("limit")) || 100;
  const limit = 100;
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [totalPages, setTotalPages] = useState(1);

  const fetchCallLogs = (
    url = `${baseUrl}/stats/all-calls/?page=${currentPage}`
  ) => {
    const accessToken = localStorage.getItem("authAccessToken");

    if (accessToken) {
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${accessToken}` },
        })
        .then((response) => {
          console.log("Call Logs Response:", response.data);
          setCallLogs(response.data.results);
          setNextPageUrl(response.data.next);
          setPrevPageUrl(response.data.previous);
          setTotalPages(Math.ceil(response.data.count / limit));
        })
        .catch((error) => {
          console.error("Error fetching call logs:", error);
        });
    }
  };

  useEffect(() => {
    fetchCallLogs();
  }, [currentPage]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  // Calculate total pages
  // const totalPages = Math.ceil(callLogs.length / limit);

  // Get the data for the current page
  // const currentData = callLogs.slice(
  //   (currentPage - 1) * limit,
  //   currentPage * limit
  // );

  // Handle page change
  const handlePageChange = (pageNumber) => {

    if (pageNumber < 1 || pageNumber > totalPages) {
      return;
    }

    setCurrentPage(pageNumber);
  };

  // const handleRowsPerPageChange = (value) => {
  //   setSearchParams({ currentPage, limit: value });
  //   console.log(value);
  // };

  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">
      {/* Hamburger Icon */}
      <div className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </div>

      <Sidebar showSidebar={showSidebar} toggleSidebar={toggleSidebar} />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
        <div className={`dashboard-content ${showSidebar ? "sidebar" : ""}`}>
          {showSidebar && (
            <div className="close-sidebar-button" onClick={toggleSidebar}>
              <FiX className="close-icon" />
            </div>
          )}
          <TopHeader userEmail={userEmail} />

          <div className="p-8">
            <h2 className="dashboard-header text-2xl font-semibold text-gray-800">
              Call Logs
            </h2>
            <p className="dashboard-text mt-2 text-gray-400">
              Track and manage the total number of call logs happening on the
              platform
            </p>
          </div>

          <div className="dashboard-stats grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 ml-8 mr-8">
            <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
              <div className="dashboard-stat-icon bg-blue-200 rounded-full p-2 w-fit flex items-center justify-center">
                <CallCalling className="text-blue-500 text-2xl" />
              </div>
              <h3 className="mt-2 text-sm text-slate-500 font-semibold">
                Total Number of Calls
              </h3>
              <p className="mt-2 text-2xl font-bold">
                {totalCallsData.total_calls}
              </p>
            </div>
            {/* Render other summary statistics here */}
          </div>

          <CallsLogsView>
            <div className="px-4 py-2">
              <Head title={"Call logs"} />
            </div>
            <div className="overflow-x-auto">
              <table className="min-w-full border border-gray-300">
                <thead>
                  <tr>
                    <th className="p-4">Caller</th>
                    <th className="p-4 w-30">Participant</th>
                    <th className="p-4">Duration</th>
                    <th className="p-4">Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {/* {currentData.map((log) => ( */}
                    {callLogs.map((log) => (
                    <tr key={log.id}>
                      <td className="p-4">{log.caller}</td>
                      <td className="p-4">
                        {log.participant}
                      </td>
                      {/* <td className="p-4">{log.duration || "-"}</td> */}
                      <td className="p-4">
                        {log.duration
                          ? `${Math.floor(log.duration / 60)}m ${(
                              log.duration % 60
                            ).toFixed(2)}s`
                          : "-"}
                      </td>
                      <td className="p-4">
                        {String(new Date(String(log.created_at))).slice(3, 16)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <PaginationView>
              {/* <TableRowsSelect onChange={handleRowsPerPageChange} /> */}

              <div className="paginate">
                <button
                  className="prev"
                  onClick={() => handlePageChange(currentPage - 1, prevPageUrl)}
                  disabled={currentPage === 1}
                >
                  Prev
                </button>
                <span>
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className="next"
                  onClick={() => handlePageChange(currentPage + 1, nextPageUrl)}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>
            </PaginationView>
          </CallsLogsView>
        </div>
      </div>
    </div>
  );
};

export default CallLogs;

const CallsLogsView = styled.div`
  margin: 0 1rem;
  height: 100%;
`;

const PaginationView = styled.div`
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .total {
    span {
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;

    button {
      width: 42px;
      height: 42px;
      border: none;
      outline: none;
      background-color: rgb(209 213 219);
      color: ${({ theme }) => theme.colors?.secondary};
      font-size: 0.875rem;
      font-weight: 500;
      border-radius: 6px;

      &:hover {
        background-color: rgb(209 213 220);
      }
    }

    span {
      margin: 0 10px;
      font-size: 0.875rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
