import { useEffect, useState } from "react"
import { ArrowLeft,CloseCircle } from "iconsax-react"



export function NotificationsPageHeader(){

    function handleBackClick(){
        window.history.back()
    }

    return <div className="flex gap-[25%] px-4 py-2 items-center">
                <div onClick={handleBackClick} >
                     <ArrowLeft/>
                </div>
                <h2 className="font-semibold text-2xl" >NOTIFICATIONS</h2>
    </div>
}


export function NotificationBody(){
    const [notifications , setNotifications] = useState([]);
    const [reload , setReload] = useState(0);

    function getNotifications(){
            const notificationsDb = localStorage.getItem("notification-db");
            const parsedNotifications = JSON.parse(notificationsDb)
            console.log("these are the notifications data gotten",parsedNotifications);
            setNotifications(()=>parsedNotifications);
    }

    function deleteANotification(index){
        console.log("deleting from index",index);
        const notificationsDb = localStorage.getItem("notification-db");
        const parsedNotifications = JSON.parse(notificationsDb);
        parsedNotifications.splice(index,1);
        console.log("db is now",parsedNotifications);
        const newDBdata = JSON.stringify(parsedNotifications)
        localStorage.setItem("notification-db",newDBdata)

    }

    function reloader(){
        setReload(()=>Math.random());
        console.log("reloader was called");
    }

    useEffect(function(){
        getNotifications()
    },[reload])

    return <div key={reload} className="w-full px-4 flex flex-col gap-4 pt-8" >
                {(notifications.length < 1) && <NoNotifications/> }
                {(notifications.length > 0) && (
                    notifications.map((aNotification,index) =>{
                        return <ANotificationItem handelDelete={()=>{deleteANotification(index);reloader();}} title={aNotification?.notification?.title} summary={aNotification?.notification?.body} />
                    })
                ) }

            </div>
}

function ANotificationItem({title,summary,handelDelete}){
    
    return <div className="w-full px-2 py-4 flex border-solid items-center border-green-700 border-[0.02rem] rounded-md">
                <div className="flex flex-col gap-2 w-[95%]">                    
                    <p className=" text-green-600 font-coolvetica font-semibold text-ellipsis block w-full text-nowrap overflow-hidden ">{title}</p>
                    <p className="text-sm text-ellipsis block w-full overflow-hidden">{summary}</p>
                </div>
                <CloseCircle onClick={handelDelete} className="text-green-700" />
            </div>
}

function NoNotifications(){
    return <div className="flex items-center justify-center w-full h-[100%]">
                    <p className="font-dmsans">There are currently no notifications</p>
    </div>
}