
import { motion } from "framer-motion"
import { useState} from "react";
import mixpanel from "mixpanel-browser";

import {
    FiPlus,
    FiMinus,
  } from "react-icons/fi";




export default function Faq(){    
  const [showMore,setShowMore] = useState(false);

  function toggleShowMore(){
    setShowMore(init=>!init)
  }
      const questions = [
        {
          id: 1,
          question: "What does RequestMechanic do?",
          answer:
            "RequestMechanic is a mobile application designed to lower the entry barrier into the mechanic industry by providing a platform for regular car owners to engage with mechanics",
        },
        {
          id: 2,
          question: "Can I trust the mechanic, is the mechanic a  specialist mechanic?",
          answer:
            "Yes, the automobile mechanic is a specialist mechanic specific to your vehicle’s brand and model.",
        },
        {
          id: 3,
          question: "Can I speak to a specialist mechanic at any time, 24/7 service?",
          answer:
            "Yes. This is an immediate service. specialist mechanics are available as seen on the app.",
        },
        {
          id: 4,
          question: "How close is the specialist mechanic near me?",
          answer:
            `Our system matches you with a specialist mechanic typically just 2-20 minutes
             away, ensuring the closest and most convenient specialist mechanic is prioritized
              for your needs.`,
        },
        {
          id: 5,
          question: "Is the app free to download?",
          answer:
            " Yes, you can download the app for free on the play store or app store.",
        },
        {
          id: 6,
          question: "How long does a session with a specialist mechanic last ?",
          answer:
            "specialist mechanics determine their weekly availability within a 24/7 schedule. The time period with a specialist mechanic lasts 30 minutes.",
        },
        {
          id: 7,
          question: "How are specialist mechanics paid?",
          answer:
            "specialist mechanics are paid from a session’s cost which is added to their wallet.",
        },
        {
          id: 8,
          question: "Do I need any prior technical “know how”?",
          answer:
            "No. The video call feature provides real-time assistance. The specialist mechanics follow a pre-call guide to provide efficient diagnostics and a solution in a timely manner.",
        },
        {
          id: 9,
          question: "How do I pay for the service?",
          answer:
            "You can deposit money into your wallet from which a session’s cost is deducted",
        },
        {
          id: 10,
          question: "Do I pay before or after my session?",
          answer:
            "The money is deducted from your wallet at the end of your session.",
        },
        {
          id: 11,
          question: "How much money do I need in my wallet for a session ?",
          answer:
            "The cost of a session depends on the rate of the specialist mechanic. You can deposit as much money as needed.",
        },
        {
          id: 12,
          question: "How do I rate a specialist mechanic?",
          answer:
            "You can rate your specialist mechanic on a 5-star scale from no stars for a terrible service to 5 stars for an exceptional service.",
        },
        {
          id: 13,
          question: "Do you provide a towing service?",
          answer:
            "No. However, this can be discussed with your specialist mechanic during your session.",
        },
        {
          id: 14,
          question: "Can I request a home service?",
          answer:
            "Yes. You can request a home service, or to meet anywhere during your paid consultation call with your mechanic. ",
        },
      ];

    return  <div  id="FAQs" className="w-full max-h-[400px] overflow-y-auto">
                {questions.slice(0,showMore?15:6).map((faq) => (
                    <SingleFaq id={faq.id} question={faq.question} answer={faq.answer}  />
                ))}
                <p onClick={toggleShowMore} className="text-white pt-4">{showMore?"Hide":"Expand"}</p>
  </div>
} 


function SingleFaq({id,question,answer}){
    const [expandedQuestion, setExpandedQuestion] = useState(null);
    const toggleExpansion = (id) => {
        setExpandedQuestion((prevId) => (prevId === id ? null : id));
        console.log(`visitor interested in question ${id}: ${question}`);
        mixpanel.track('FaQ Interaction',{questionId:`Question ${id}`,questionString:`${question}`});
      };
      const animation = {
        initial:{
            zIndex:-1,
            opacity:0,
            transform:"translateY(-10%)",
            display:"none"
        },
        slideOut:{
            display:"block",
            zIndex:-1,
            opacity:1,
            transform:"translateY(0%)",
            transition:{
                duration:0.8
            },
        }
      }

    return <div key={id} className="border-b border-t border-dark">
                <button className="w-full text-white text-sm flex items-center justify-between cursor-pointer py-2 pr-3" onClick={() => toggleExpansion(id)} >
                <p className="text-white text-sm text-left font-medium">{question}</p>
                <p>
                    {expandedQuestion === id ? (
                    <FiMinus className="h-4 w-4 text-gray-500" />
                    ) : (
                    <FiPlus className="h-4 w-4 text-gray-500" />
                    )}
                </p>
                </button>
    {expandedQuestion === id && (
      <motion.div className="text-sm text-gray-300 font-extralight py-3" variants={animation} initial="initial" animate = {expandedQuestion?"slideOut":""}  >{answer}</motion.div>
    )}
  </div>
}