import "./index.css";
import {Header,Body,Services,Footer,WatchIsWork,CarLove,Recommendations,Comparison,Faq} from "./components";


export default function DetailingPage(){
    return <div className="w-screen h-fit bg-black text-white overflow-x-hidden ">
                <Header/>
                <Body/>
                <Services/>
                <WatchIsWork/>
                <CarLove/>
                <Recommendations/>
                <Comparison/>
                <Faq/>
                <Footer/>
    </div>
}