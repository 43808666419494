import React, { useState, useEffect,useContext } from 'react';
import AuthContext from "../Context/authContext";
import Sidebar from './Sidebar';
import TopHeader from './TopHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { FiMenu, FiX } from 'react-icons/fi';
import axios from 'axios';
import { EmptyWalletAdd } from 'iconsax-react';
import mixpanel from 'mixpanel-browser'
import TransactionHistory from './transactionHistory';
import { PeriodConstants } from './utils';

 

const Transactions = ({userEmail}) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState(0);
  const [transactionHistoryData, setTransactionHistoryData] = useState([]);
  const [processedData, setProcessedData] = useState(transactionHistoryData);
  const [searchValue, setSearchValue] = useState();
  const [filterValue, setFilterValue] = useState();
  const genData = useContext(AuthContext);
  const baseUrl = genData.baseUrl;
  // const isProd = genData.isProd;



  // Fetch transaction history data from the API with authentication
  useEffect(() => {
    const accessToken = localStorage.getItem('authAccessToken');
  
    if (accessToken) {
      axios
        .get(`${baseUrl}
/stats/transaction-history/`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
          },
        })
        .then((response) => {
          console.log('Transaction History Response:', response.data);
  
          const dataFromEndpoint = response.data || {};
          setTransactionHistoryData(dataFromEndpoint);
        })
        .catch((error) => {
          console.error('Error fetching transaction history data:', error);
        });
    } else {
      console.error('Authentication credentials were not provided.');
    }
  }, []);

  useEffect(()=>{

    const newData = transactionHistoryData?.history?.filter(function(aTransaction){
      return String(`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name} ${aTransaction.amount}`).toLowerCase().includes(searchValue?.toLowerCase());
    })
    setProcessedData(init=>newData)

  },[searchValue])

  useEffect(()=>{

    if(transactionHistoryData?.history?.length > 0){
        setProcessedData(init=>transactionHistoryData?.history)
    }

  },[transactionHistoryData])

  useEffect(()=>{

    switch(true){
      case filterValue === "owner":{
            const newData = transactionHistoryData?.history?.filter(function(aTransaction){
              return aTransaction.user_detail.account_type === "owner"
             })
            setProcessedData(init=>newData)
      }
      break
      case filterValue === "mechanic":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.user_detail.account_type === "mechanic"
         })
        setProcessedData(init=>newData)
      }
      break
      case filterValue === "credit":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.transaction_type === "fund"
         })
        setProcessedData(init=>newData)
      }
      break
      case filterValue === "debit":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.transaction_type !== "fund"
         })
        setProcessedData(init=>newData)
      }
      break
    }
    // const newData = transactionHistoryData?.history?.filter(function(aTransaction){
    //   return String(`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name} ${aTransaction.amount} `).toLowerCase().includes(searchValue.toLowerCase());
    // })
    // setProcessedData(init=>newData)

  },[filterValue])

  function handleSearchChange(value){
    setSearchValue(init=> value)
  }

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  function handelFilterChange(value){
    setFilterValue(init=>value)
  }

  useEffect(()=>{

    switch(true){
      case filterValue === "owner":{
            const newData = transactionHistoryData?.history?.filter(function(aTransaction){
              return aTransaction.user_detail.account_type === "owner"
             })
            setProcessedData(init=>newData)
      }
      break
      case filterValue === "mechanic":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.user_detail.account_type === "mechanic"
         })
        setProcessedData(init=>newData)
      }
      break
      case filterValue === "credit":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.transaction_type === "fund"
         })
        setProcessedData(init=>newData)
      }
      break
      case filterValue === "debit":{
        const newData = transactionHistoryData?.history?.filter(function(aTransaction){
          return aTransaction.transaction_type !== "fund"
         })
        setProcessedData(init=>newData)
      }
      break
    }
    // const newData = transactionHistoryData?.history?.filter(function(aTransaction){
    //   return String(`${aTransaction.user_detail.first_name} ${aTransaction.user_detail.last_name} ${aTransaction.amount} `).toLowerCase().includes(searchValue.toLowerCase());
    // })
    // setProcessedData(init=>newData)

  },[filterValue])

  // Handle click event on a div
  // const handleDivClick = (index) => {
  //   setClickedDivs((prevClickedDivs) => ({
  //     ...prevClickedDivs,
  //     [index]: !prevClickedDivs[index]
  //   }));
  // };

  // const creditColor = '#1EB85F'; // Green
  // const debitColor = '#FF0000';  // Red



  // const handleClick = () => {
  //   setSelected(!selected);
  // };


  


  return (
    <div className="overall-dashboard-div flex h-screen bg-white-100">

            {/* Hamburger Icon */}
            <div className="hamburger-icon" onClick={toggleSidebar}>
        <FiMenu className="hamburger" />
      </div>

      <Sidebar 
      showSidebar={showSidebar}
      toggleSidebar={toggleSidebar}
       />

      <div className="dashboard-div flex-1 overflow-x-hidden overflow-y-auto">
      <div className={`dashboard-content ${showSidebar ? 'sidebar' : ''}`}>
  {showSidebar && (
    <div className="close-sidebar-button" onClick={toggleSidebar}>
      <FiX className="close-icon" />
    </div>
  )}
        <TopHeader userEmail={userEmail} />

      
        <div className="p-8">
          <h2 className="dashboard-header text-2xl font-semibold text-gray-800">Transactions</h2>
          <p className="dashboard-text mt-2 text-gray-400">
            Track and manage the total number of transactions happening on the platform.
          </p>
        </div>

        <div className="dashboard-stats grid grid-cols-2 md:grid-cols-4 gap-4 mt-8 ml-8 mr-8">
        <div className="dashboard-stat-container rounded-lg border-gray-100 border p-4 shadow-md">
        <div className="dashboard-stat-icon bg-pink-200 rounded-full p-2 w-fit flex items-center justify-center">
            <EmptyWalletAdd  className="text-pink-500 text-2xl" />
            </div>
            <h3 className="mt-2 text-sm text-slate-500 font-semibold">Total Number of Transactions</h3>
            <p className="mt-2 text-2xl font-bold">{transactionHistoryData.total_transactions}</p>
          </div>
        </div>

  {/* Transaction History */}
  {/* <div className="font-semibold bg-gray-100 w-full mt-4">
  <div className="p-4 sm:flex">
    <div className="text-xl w-full mb-4 sm:w-1/3">Transaction History</div>
  </div>

  <div className="overflow-x-auto">
    <table className="min-w-full border border-gray-300">
      <thead>
        <tr>
          <th className="p-4">Total Transactions</th>
          <th className="p-4">Total Funds Added</th>
          <th className="p-4">Total Withdrawals</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="p-4">₦{transactionHistoryData.total_transactions}</td>
          <td className="p-4">₦{transactionHistoryData.total_funds_added}</td>
          <td className="p-4">₦{transactionHistoryData.total_withdrawals}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div> */}



{/* <div className="transactionHistory-div border border-gray-300 ml-8 mt-8 mr-8 rounded"> */}
  {/* <div className="transaction-history-heading-div flex items-center space-x-4 border-b p-8">
    <h2 className="transaction-history-heading text-xl font-semibold">Transaction History</h2>
    
    <div className="flex-1 flex items-center rounded-lg border border-gray-300 p-4">
      <FontAwesomeIcon icon={faSearch} className="text-gray-400" />
      <input
        type="text"
        placeholder="Search transaction by date, amount, reference, etc."
        className="ml-2 focus:outline-none w-full"
      />
    </div>

    <div className=" flex items-center rounded-lg border border-gray-300 p-2">
      <FontAwesomeIcon icon={faCalendar} className="text-gray-400" />
      <span className="ml-2 text-gray-600">Date</span>
      <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-gray-400" />
    </div>

    <div className="flex items-center rounded-lg border border-gray-300 p-2">
      <FontAwesomeIcon icon={faFilter} className="text-gray-400" />
      <span className="ml-2 text-gray-600">Filters</span>
      <FontAwesomeIcon icon={faChevronDown} className="ml-2 text-gray-400" />
    </div>

  </div> */}


  
 {/* <div className="font-semibold bg-gray-100 w-full">
  <div className="p-4 hidden sm:flex">
    <div className="text-xl w-1/4">Date</div>
    <div className="text-xl w-1/4">User</div>
    <div className="text-xl w-1/4">Amount</div>
    <div className="text-xl w-1/4">Transaction Type</div>
  </div>


  {dynamicDivs.map((data, index) => (
    <div
      key={index}
      className={`transaction-history-div p-4 ${clickedDivs[index] ? 'bg-white border border-2 border-black' : 'bg-gray-100'}`}
      onClick={() => handleDivClick(index)}
    >
      <div className="flex flex-col sm:flex-row items-center justify-center mb-2">
        <div className="w-full sm:w-1/4 text-center sm:text-left">
          <div className="calandar-date-div flex items-center">
            <FontAwesomeIcon icon={faCalendar} className="text-gray-400 mr-2" />
            <div>
              <div className='data-date text-left'>{data.date}</div>
              <div className="text-xs text-gray-500 text-left">12:53PM</div>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-1/4 text-center sm:text-left flex items-center">
          <div className="rounded-full bg-gray-300">
            <img src={ellipse} alt={`Ellipse ${index}`} />
          </div>
          <div className="ml-2">
            <div className='data-user text-left'>{data.user}</div>
            <div className="text-xs text-gray-500 text-left">Mechanic</div>
          </div>
        </div>
        <div className="transaction-amount-div w-full sm:w-1/4 text-left">{data.amount}</div>
        <div className="w-full sm:w-1/4 text-center sm:text-left">
          <div
            className="credit-debit-div p-2 text-center text-white w-24"
            style={{ backgroundColor: data.type === 'Credit' ? creditColor : debitColor }}
          >
            {data.type}
          </div>
        </div>
      </div>
    </div>
  ))}
</div> */}

<TransactionHistory data={processedData} handleSearch={handleSearchChange} handleChange={handelFilterChange} />


    {/* </div> */}


    </div>
</div>
</div>
  );
};

export default Transactions;
