
import React, { useState, useEffect,useContext } from 'react';
import AuthContext from '../../Context/authContext';
import { Success,Failure,Pending } from "./components"
import { useSearchParams } from "react-router-dom"
import axios from "axios";




export default function RegistrationVerification(){
    const [searchParams]  = useSearchParams();
    const [isVerified,setIsVerified] = useState(false);
    const [isPending,setIsPending] = useState(true);
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;
    useEffect(function(){
        const accessToken = localStorage.getItem('authAccessToken');
        axios.post(`${baseUrl}/user/verify-otp/`,{
            otp_code:searchParams.get("otp"),
            email:searchParams.get("email")
        },{
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${accessToken}`,
            },
        }).then(function(response){
            setIsPending(false);
            setIsVerified(true);
            return response;
        }).catch(function(error){
            setIsVerified(false);
            setIsPending(false);
            console.log(error);
        });
//     }
// )

        // axios.get(`${baseUrl}/user/verify-otp?email=${searchParams.get("email")}&otp=${searchParams.get("otp")}`).then(function(response){
        //     setIsPending(false);
        //     setIsVerified(true);
        //     return response;
        // }).catch(function(error){
        //     setIsVerified(false);
        //     setIsPending(false);
        //     console.log(error);
        // })

    },[])

    return (
            <div>
                <p className="w-screen text-center text-2xl font-bold font-coolvetica mt-8">Verification</p>
                {(!isPending && isVerified) && <Success/>}
                {(!isPending && !isVerified) && <Failure/>}
                {isPending  && <Pending/>}
            </div>
    )
}


