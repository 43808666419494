import * as Yup from "yup";
import Button from '../Components/button/index';
import { Form, Formik } from "formik";
import FormikControl from "../Components/formik/formikControl";
import { Link, useNavigate } from "react-router-dom";
import { loginOwner } from "../Components/store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { LoaderKey } from "../Components/loaderKey";
import { useEffect, useState } from "react";
import { TextError } from "../Components/utils";
import { resendOtp } from "../Components/store/actions/authAction";
import { messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import {useContext} from "react";
import AuthContext from "../Context/authContext";


 

const LoginII = ({password, setPassword}) => {
    const navigate = useNavigate(); // Initialize useNavigate hook
    const dispatch = useDispatch();  //Initialize useDispatch hook
    const loading = useSelector((state) =>state.app);
    const [errorMessage, setErrorMessage] = useState('');
    const genData = useContext(AuthContext);
    const baseUrl = genData.baseUrl;
    // const isProd = genData.isProd;

    
    useEffect(()=>{
        //check and access the tokens in local or session storage
        const accessToken = localStorage.getItem('authAccessToken') || sessionStorage.getItem('authAccessToken');
        const refreshToken = localStorage.getItem('authRefreshToken') || sessionStorage.getItem('authRefreshToken')
        const userEmail = localStorage.getItem('userEmail') || sessionStorage.getItem('userEmail');
    
        if (accessToken && refreshToken && userEmail) {
            // Dispatch the login action to set the user as logged in with these tokens

            // dispatch(loginOwner({
            //     data:{email:userEmail, accessToken, refreshToken},
            //     rememberMe: !!localStorage.getItem('authAccessToken') //returns true if localStorage isn't null
            // }));
        }
    
    }, [dispatch])


    const initialValues = {
        email: "",
        password: "",
        rememberMe: false,  // Initialize rememberMe field to false (unchecked) by default
    };
    const onSubmit = async (values, { setSubmitting, setErrors }) => {
        try {
            setPassword(values.password);
            //remember me functionality

            if (values.rememberMe) { // if rememberMe is true i.e (the rememberMe box is ticked), it saves in local storage
                localStorage.setItem("rememberMe", "true");
            } 
            else {
                localStorage.removeItem("rememberMe");
            }

            const res = await dispatch(loginOwner({data:{...values}, rememberMe:values.rememberMe}))

            if (res.payload.success === true) {
                if (res.payload.data.completed_registration === false) {
                    if (!res.payload.data.first_name) {
                        if (res.payload.data.account_type === "owner") {
                            navigate("/carowner-create-account2");
                        } else if (res.payload.data.account_type === "mechanic") {
                            navigate("/mechanic-create-account2");
                        }
                    } else {
                        if (res.payload.data.account_type === "owner") {
                            navigate("/carowner-create-account3");
                        } else if (res.payload.data.account_type === "mechanic") {
                            navigate("/mechanic-create-account3");
                        }
                    }
                    
                } else {
                    if (res.payload.data.account_type === 'owner') {
                        navigate("/mechanic-near-me");
                        sendDeviceToken();
                    } else if (res.payload.data.account_type === 'client') {
                        // navigate("/mechanic-dashboard");
                } else if (res.payload.data.account_type === 'mechanic') {
                    sendDeviceToken();
                    navigate("/mechanic-dashboard");
                        console.log('mechanic');
                }
                }
            }

            else if (res.payload.message === "Email Is Not Verified.") {
                const res2 = await dispatch(resendOtp({data:values}))
                if (res2.payload.success === true) {
                    navigate("/user-otp")
                    // console.log("resent otp for unverified email")
                     // localStorage.setItem("CreateAccountEmail", values.email);
                
                    // setErrorMessage(res.payload.message);
                }
            }

            else if (res.payload.success === false) {
                const errorMessage = res.payload.message || "An unknown error occurred";
                setErrorMessage(errorMessage); // Set the error message
              }
            } catch (err) {
              // console.error("Create Account failed!:", err);
              const errorMessage = err.res?.message;
              setErrorMessage(errorMessage);
          } finally {
            setSubmitting(false); // Reset submitting state in Formik
          }
        
    };
      
    const validationSchema = Yup.object({
        email: Yup.string()
          .email("Invalid email format")
          .required("Required"),
        password: Yup.string()
          .required("Required")
          .matches(
            // /^.{8,}$/,
            // "Must Contain at least 8 characters"
            // /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*:;'><.,/?}{[\]\-_+=])(?=.{8,})/,
            // "Must Contain at least 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character."
        ),
    });

    function sendDeviceToken(){
        getToken(messaging,{vapidKey:"BIyETeKClKJtJuC3dQgfrjkEnvxmuC1d1w0T2WEYVtQyeHwxyxMuxqoAEdzBauIElj_njxjqusYzQDf_ZlUhh48"})
        .then(function(token){
              const accessToken = localStorage.getItem("authAccessToken")
              storeDeviceToken(token)
              if(accessToken){
                axios.put(`${baseUrl}/user/profile/call-token/`,{
                  call_token:token
                },{
                  headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                  }
                }).then(function(response){
                    console.log(response);
                    return response
                }).catch(function(error){
                    console.log(error);
                })
              }
          return token
        }).catch(function(error){
          console.log(error);
        })
    }

    function storeDeviceToken(token){
        localStorage.setItem("deviceToken",token)
    }

    return (
        <div className="sharp-sans flex flex-col justify-center items-center">
            <Helmet>
                <title>Login to RequestMechanic to find the best mechanics</title>
                <meta name="description" content="Login to get access to the best mechanic nearby right now."></meta>
                <link rel="canonical" href="https://www.requestmechanic.com/login-user" />
            </Helmet>
            <div className="mt-10">

                <div className="space-y-1">
                    <h1 className="sharp-sans-bold text-2xl">Log in to your account</h1>
                    <p className="sharp-sans text-xs font-medium text-[#878C98]">Hello there, welcome back</p>
                </div>

                <div className="mt-10">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                    >
                        {(formik) => {
                            return (
                                <Form className="space-y-[20px]">
                                    <FormikControl
                                        control="input"
                                        type="email"
                                        label="Email address"
                                        name="email"
                                        placeholder="Enter your email address"
                                        className="!min-w-[345px]"
                                    />
                                    <FormikControl
                                        control="input"
                                        type="password"
                                        label="Password"
                                        name="password"
                                        placeholder="Enter your password"
                                        className="!min-w-[345px]"
                                    />
                                    {errorMessage && <TextError>{errorMessage}</TextError>}
                                    <div className="flex mt-[35px] sharp-sans justify-between">
                                        <div className="space-x-1 flex">
                                            <input
                                                type="checkbox"
                                                id="rememberMe"
                                                className="bg-white"
                                                onChange={formik.handleChange}
                                                checked={formik.values.rememberMe}
                                            />
                                            <p className="text-[#111111] font-[400]  text-xs">Remember me</p>
                                        </div>
                                        <Link to="/reset-password" className="text-xs text-[#BD0000] sharp-sans-bold">Forgot Password?</Link>
                                    </div>
                                    <div className="pt-[5px]">
                                        <Button
                                            className="bg-greeen text-white !mt-[30px]"
                                            type="submit"
                                            disable={!formik.isValid || formik.isSubmitting}
                                            loading={loading[LoaderKey.LOGIN_KEY]?.loading}
                                        >
                                            {formik.isSubmitting ? 'Logging in...' : 'Log in'}
                                        </Button>
                                        
                                    </div>
                                </Form>
                            )
                        }}
                        
                    </Formik>
                    <Link to="/create-account" className="flex justify-center font-[500] text-xs mt-3">Don't have an account? {""}<span className="pl-1 text-greeen sharp-sans-bold">Sign Up</span></Link>
                </div>
            </div>
        </div>
    );
}
 
export default LoginII;