import React, { useEffect, useState } from "react";
import { CloseCircle as CloseC, Warning2 as Warn } from "iconsax-react";
import { motion, AnimatePresence } from "framer-motion";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Oval } from "react-loader-spinner";
import { set } from "lodash";

const isProd = window.location.hostname === "requestmechanic.com";

const baseUrl = `${
  isProd
    ? "https://requestnowmechanic.com/v1"
    : "https://requestnowmechanic.com/staging/v1"
}`;

export function UpdateTestEnvironmentModal({ showModal, setShowModal }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { email } = useParams();
  const [mechanic, setMechanic] = useState(null);
  const [mode, setMode] = useState('');

  const handleModeChange = (e) => {
    setMode(e.target.value);
    console.log(mode);
  };

  const modeOptions = [
    {
      label: 'Test',
      value: 'test'
    },
    {
      label: 'Live',
      value: 'live'
    }
  ]

  useEffect(() => {
    const accessToken = localStorage.getItem("authAccessToken");
    axios
      .get(`${baseUrl}/user/user/${email}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(function (response) {
        setMechanic(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [email]);

  const updateMode = async () => {
    const accessToken = localStorage.getItem("authAccessToken");
    setLoading(true);

    try {
      const response = await axios.put(
        `${baseUrl}/client/admin/mechanic/${mechanic.id}/mode/`,
        { user_id: mechanic.id, mode: mode },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        setMode('');
        setLoading(false);
        setShowModal(false);
        toast.success("Mode successfully updated");
      }
    } catch (error) {
      setMode('');
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <AnimatePresence>
      {showModal && (
        <ModalView
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="modal-overlay"
        >
          <motion.div
            initial={{ y: 1000 }}
            animate={{ y: 0 }}
            exit={{ y: 100 }}
            transition={{ duration: 0.3 }}
            className="display"
            onClick={(e) => e.stopPropagation()}
          >
            <ModalContent>
              <div className="header">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setMode("");
                  }}
                >
                  <CloseC />
                </button>
              </div>
              <ContentView>
                <div className="content">
                  <h3>Set Mechanic Environment</h3>
                  <div className="password-input">
                    <label htmlFor="file">Update environment</label>
                    <select onChange={handleModeChange}>
                      <option value="">Select a Mode</option>
                      {modeOptions.map((option) => (
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>
                  </div>

                  <div className="actions">
                    <button
                      type="button"
                      className="continue"
                      onClick={() => {
                        setShowModal(false);
                        setMode("");
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="activate"
                      onClick={updateMode}
                    >
                      {loading ? (
                        <Oval
                          color="#FFFFFF"
                          height={20}
                          width={20}
                          strokeWidth={3}
                        />
                      ) : (
                        "Update"
                      )}
                    </button>
                  </div>
                </div>
              </ContentView>
            </ModalContent>
          </motion.div>
        </ModalView>
      )}
    </AnimatePresence>
  );
}

const ModalView = styled(motion.div)`
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /* background: transparent; */
  z-index: 9009;
  display: flex;
  align-items: center;
  justify-content: center;

  .display {
    background-color: #ffffff;
    z-index: 30000;
    position: fixed;
    border-radius: 4px;
    width: 40vw;
    padding: 30px 0px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 10px;
    overflow-y: scroll;

    @media (max-width: 600px) {
      width: 90vw;
    }
  }
`;

const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  margin: 0;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 17px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    h3 {
      font-size: 1rem;
      font-weight: 600;
    }
  }
`;

const ContentView = styled.div`
  width: 100%;

  .content {
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: flex-start;
    flex-direction: column;

    .password-input {
      margin: 2rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      input, select {
        width: 100%;
        padding: 0.6rem 0.5rem;
        border: 1px solid #ccc;
        border-radius: 5px;
        text-indent: 0.7rem;
        color: #cccccc;

        &::placeholder {
          font-size: 0.8rem;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .info {
      width: 100%;
      border-radius: 6px;
      padding: 14px 5px;
      padding-bottom: 5px;
      display: flex;
      margin-bottom: 10px;
      background-color: #f3b1b1;

      .icon {
        margin-right: 5px;

        path {
          stroke: red;
        }
      }

      p {
        font-size: 0.9rem;
        font-weight: 400;
        margin-bottom: 12px;
        color: #000000;
      }
    }

    .green-icon {
      path {
        stroke: red;
      }
    }

    h3 {
      font-size: 1.2rem;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 10px;
    }

    p {
      font-size: 0.9rem;
      font-weight: 400;
      color: #878c98;
      margin-bottom: 20px;
      line-height: 19px;
    }

    .warn {
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 1rem;
      margin: 10px 0;
      background-color: red;
      font-weight: 500;
      border-radius: 10px;

      h2 {
        width: 100%;
        font-weight: 500;
        line-height: 19px;
        color: red;
      }
    }

    .actions {
      width: 100%;
      gap: 1.2rem;
      display: flex;
      align-items: center;
      margin-top: 1.5rem;

      .activate {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid #245444;
        padding: 0.45rem 2rem;
        width: 70%;
        border-radius: 5px;
        color: #245444;
        font-weight: 500;
      }

      .continue {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffff;
        border: 1px solid #afafaf;
        padding: 0.45rem 2rem;
        width: 70%;
        border-radius: 5px;
        color: #afafaf;
        font-weight: 500;
      }
    }
  }
`;
